import axios from '@/config/httpConfig'
import qs from  'qs'
// //登陆
// export const logins=(data)=> {
//     return axios('post','api/Website/platformLogin', data);

// }
//图片上传
export const upLoadMoreImg=(data)=> {
    return axios('post','/api/Oss/photoGalleryFind', data)
}
//版位列表
export const PositionTheList=(data)=> {
    return axios('post','Website/PositionTheList', data)
}
//全部商品 列表
export const allCommodity=(data)=> {
    return axios('post','Goods/terrace_goods_list', data)
}
//订单列表 列表
export const orderlistList=(data)=> {
    return axios('post','Orders/getAdminOrderList', data)
}
// 上传图片多张或单张
export const fileUploadings = data => {//结束
    return request('post', 'Oss/platform/fileUploading', data, true)
  }

  // 图片上传-富文本
export const shopUpload=(data)=> {
    return axios('post','Oss/shop/upload', data)
}