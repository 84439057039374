import axios from '@/config/httpConfig'
import qs from  'qs'

//获取特殊客户列表
export const getSpecialAccountList=(data)=> {
    return axios('post','/Distribution/getSpecialAccountList', data);
}

//添加特权客户
export const insertSpecialAccount=(data)=> {
    return axios('post','/Distribution/insertSpecialAccount', data);
}
//删除特权客户
export const delSpecialAccount=(data)=> {
    return axios('post','/Distribution/delSpecialAccount', data);
}
//查看特权绑定客户
export const getBindAccountList=(data)=> {
    return axios('post','/Distribution/getBindAccountList', data);
}
//移除特权绑定客户
export const removeAccount=(data)=> {
    return axios('post','/Distribution/removeAccount', data);
}

//设置等级客户
export const config=(data)=> {
    return axios('post','/Commission/platform/level/config', data);
}
//获取等级客户信息
export const getConfig=(data)=> {
    return axios('get','/Commission/platform/level/config', data);
}
//获取分红奖列表
export const getDividendConfig=(data)=> {
    return axios('get','Commission/platform/getDividendConfig', data);
}
//修改添加分红奖列表
export const saveConfig=(data)=> {
    return axios('post','Commission/platform/saveConfig', data);
}
//删除分红奖列表
export const delDividendConfig=(data)=> {
    return axios('post','Commission/platform/delDividendConfig', data);
}