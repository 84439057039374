<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				<div class="signature">
					<div class="search-cont">
						<div>
							<el-button type="primary" size="small" @click="addRole" v-has="'添加角色'"  > 添加角色</el-button>
						</div>
						<!-- <div style="margin-left: 40px;">
							<el-button type="primary" size="small" @click="controlPool"> 权限池</el-button>
						</div> -->
					</div>
					<div class="search-cont" style="margin-top:10px">
						<el-table border :data="tableData" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
							<el-table-column prop="role_id" label="角色ID" align="center">
							</el-table-column>
							<el-table-column prop="role_nickname" label="角色名称" align="center">
							</el-table-column>
							<el-table-column prop="state" label="默认" align="center">
								<template slot-scope="scope">   
									<span v-if="scope.row.state == 'Y'">使用</span>
									<span v-else>不使用</span>
								</template>
							</el-table-column>
							<el-table-column prop="created_at" label="创建时间" align="center">
							</el-table-column>
							<el-table-column prop="address" label="操作" align="center">
								<template slot-scope="scope">
									<el-button size="small" v-if="scope.row.forbid_modification=='N'" type="text" @click="roleModify(scope.row)" v-has="'修改'" >修改</el-button>
									<el-button size="small" type="text" @click="roleDetail(scope.row)" v-has="'查看详情'" >查看详情</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<!-- 分页 -->
				<div class="pagecurrent">
					<div class="pagination-container">
						<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 :page-sizes="[10,20,30,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!--权限池-->
		<el-dialog title="客户信息" :visible.sync="controlPoolShow" width="30%">
			<el-form ref="poolform" :model="poolform" :rules="poolrules" label-width="120px">
				<el-form-item label="权限标识:" prop="permission_name">
					<el-input class="inputwidth" type="text" size="small" v-model="poolform.permission_name"></el-input>
				</el-form-item>
				<el-form-item label="权限昵称:" prop="permission_nickname">
					<el-input class="inputwidth" type="text" size="small" v-model="poolform.permission_nickname"></el-input>
				</el-form-item>
				<el-form-item label="功能模块:" prop="main_part_name">
					<el-input class="inputwidth" type="text" size="small" v-model="poolform.main_part_name"></el-input>
				</el-form-item>
				<el-form-item label="vue路由关联:" prop="relevance">
					<el-input class="inputwidth" type="text" size="small" v-model="poolform.relevance"></el-input>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="controlPoolShow=false">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmit('poolform')">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getRoleList
	} from '@/api/plat/setting.js'
	export default {
		data() {
			return {
				loading:true,
				poolform: {
					permission_name: '',
					permission_nickname:'',
					main_part_name:'',
					relevance:''
				},
				poolrules: {
					permission_name: [{
						required: true,
						message: '请填写权限标识',
						trigger: 'blur'
					}],
					permission_nickname: [{
						required: true,
						message: '请填写权限昵称',
						trigger: 'blur'
					}],
					main_part_name: [{
						required: true,
						message: '请填写功能模块',
						trigger: 'blur'
					}],
					relevance: [{
						required: true,
						message: '请填写vue路由关联',
						trigger: 'blur'
					}]
				},
				controlPoolShow: false,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			//短信签名列表
			getList() {
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
				}
				getRoleList(data).then(res => {
						this.loading=false;
						this.tableData = res.data.list;
						this.total = res.data.count;
				})
			},

			handleSizeChange(size) {
				this.pagesize = size;
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList();
			},
			addRole() {
				this.$router.push({
					path: '/merchantsettingadmin/jurisdictionsetting/addRole',
					query: {
						type:'add'
					}
				})
			},
			roleDel() {

			},
			roleModify(row) {
				this.$router.push({
					path: '/merchantsettingadmin/jurisdictionsetting/addRole',
					query: {
						id: row.role_id,
						
					}
				})
			},
			roleDetail(row) {
				this.$router.push({
					path: '/merchantsettingadmin/jurisdictionsetting/addRole',
					query: {
						id: row.role_id,
						type:'detail'
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.signature {
		padding: 15px;
		border-radius: 6px;
		// min-height: 300px;
		background-color: #f2f2f6;
		margin-bottom: 20px;

	}

	.search-cont {
		border-radius: 8px;
		background-color: #fff;
		padding: 10px 50px 10px 20px;
		display: flex;
		flex-direction: row;
		// justify-content: space-between;
	}

	.inputwidth {
		width: 300px;
	}
	.submitBtn {
		text-align: right;
		margin-top: 20px;
	}
</style>
