<template>
  <div>
    <div style="border-radius: 10px; padding-bottom: 0px; background-color: #F2F2F6;">
      <div class="main">

         <!-- 当前选择的自惠商品 -->
         <div class="goodsinfo2" v-if="this.grade >= 2 && this.goodsList2.length > 0">
          <div class="title">当前自惠礼包包含商品</div>
          <div class="main-cont bgwt mt10">
            <el-table :data="goodsList2" style="width: 100%" min-height="100" max-height="400" border
              :header-cell-style="{ background: '#f3f8fe', color: '#606060' }" element-loading-text="拼命加载中..."
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
              <el-table-column label="商品名称" width="350" align="left">
                <template slot-scope="scope">
                  <div class="el_name" style="display: flex;">
                    <div style="width:70px;height:70px;">
                      <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                        :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                    </div>
                    <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                    <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                      <p>{{ scope.row.goods_name }}</p>
                      <p class="mt10">{{ scope.row.count }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="规格明细" align="center">
                <template #default="{ row }">
                  <span v-for="(item, index) in row.goods_sku[0].value" :key="index">{{ item.specname }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="当前库存" align="center">
                <template #default="{ row }">
                  <div>{{ row.skuInfo[0].sku_stock }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="del(scope.row,'zh')"><span style="color:rgb(252, 10, 10)"
                      v-has="'删除'">删
                      除</span></el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2" :current-page="currentPage2" :page-sizes="[5, 10, 20, 30]"
            :page-size="pagesize2" layout="total, sizes, prev, pager, next, jumper" :total="total2">
          </el-pagination> -->
          </div>
        </div>
        <!-- 当前选择的礼包商品 -->
        <div class="goodsinfo" v-if="this.grade >= 2 && this.goodsList.length > 0">
          <div class="title">当前礼包包含商品</div>
          <div class="main-cont bgwt mt10">
            <el-table :data="goodsList" style="width: 100%" min-height="100" max-height="400" border
              :header-cell-style="{ background: '#f3f8fe', color: '#606060' }" element-loading-text="拼命加载中..."
              element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
              <el-table-column label="商品名称" width="350" align="left">
                <template slot-scope="scope">
                  <div class="el_name" style="display: flex;">
                    <div style="width:70px;height:70px;">
                      <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                        :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                    </div>
                    <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                    <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                      <p>{{ scope.row.goods_name }}</p>
                      <p class="mt10">{{ scope.row.count }}</p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="规格明细" align="center">
                <template #default="{ row }">
                  <span v-for="(item, index) in row.goods_sku[0].value" :key="index">{{ item.specname }} </span>
                </template>
              </el-table-column>
              <el-table-column prop="goods_stock" label="当前库存" align="center">
                <template #default="{ row }">
                  <div>{{ row.skuInfo[0].sku_stock }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="del(scope.row)"><span style="color:rgb(252, 10, 10)"
                      v-has="'删除'">删
                      除</span></el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <!-- <el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2" :current-page="currentPage2" :page-sizes="[5, 10, 20, 30]"
            :page-size="pagesize2" layout="total, sizes, prev, pager, next, jumper" :total="total2">
          </el-pagination> -->
          </div>
        </div>


        <div class="djmc">
          <span style="color: red;font-size: 14px;">*</span><span
            style="font-size: 16px; font-weight: 700;">等级名称：</span><el-input v-model="level_name" maxlength="10"
            show-word-limit placeholder="请输入等级名称" style="width: 300px; margin-top:-10px;"
            :class="'listIcon' + this.grade">
          </el-input>
        </div>
        <div class="dqqy">
          <span style="color: red; margin-top: 10px;">*</span>
          <span style="width: 150px; margin-top: 10px;font-weight: 700;font-size: 16px;">当前等级权益：</span>
          <span v-if="this.grade !== '1'" style="margin-top: 10px;margin-left: -5px;font-weight: 600;">1、自购优惠：</span><span
            v-if="this.grade !== '1'">购买升级商品每件可减 <el-input v-if="this.grade !== '1'" v-model="self_purchase"
              placeholder="" style="width: 150px; margin-left: 0px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">元</template></el-input></span>
                
          <span v-if="this.grade == '1'" style="margin-top: 10px;font-weight: 700;">无</span>
          <div>
            <el-button type="text" style="font-size: 14px;margin-top: 0px;margin-left: 30px;"
                  @click="chooseGoods('zh')" v-has="'选择礼包'"  v-if="this.grade !== '1'">选择商品</el-button>
          </div>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade !== '1'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">2、直推奖：</span><span>直属下级购买升级商品，每件可获得<el-input
              v-model="direct_thrust" placeholder="" style="width: 120px; margin-left: 10px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">元</template></el-input></span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade !== '1'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">3、平级奖：</span><span>下级购买升级商品，每件可获得<el-input
              v-model="parallel_award" placeholder="" style="width: 160px; margin-left: 10px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{10,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"><template
                slot="append">元</template></el-input></span>
        </div>

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade > '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">4、极差奖：</span><span>下级购买升级商品，每件可获得

            <el-input v-model="poor_prize" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">元</template></el-input>
          </span>
        </div>
        <!-- 
        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">5、分红奖：</span>规则展示如全局配置所示<span > 
          </span>
        </div> -->

        <div class="dqqy" style="margin: 20px 0;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;"><span v-if="this.grade == '2'" style="font-weight: 600;">4</span ><span  v-else style="font-weight: 600;">5</span>、分红奖：</span><span style="margin-top: 10px;">规则展示如全局配置所示
          </span>
        </div>

        <div class="dqqy" style="margin: 20px 0;margin-bottom: 0;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;"><span v-if="this.grade == '2'" style="font-weight: 600;">5</span ><span  v-else style="font-weight: 600;">6</span>、合作奖：</span>
        </div>

        <div class="dqqy" style="margin: 5px 0; margin-left: 25px;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;">获得合作奖条件：</span><span>业绩达到
            <el-input v-model="collaboration_award" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">元</template></el-input>
            (直属下级中去除掉，最高业绩)</span>
        </div>
        <div class="dqqy" style="margin: 5px 0; margin-left: 25px;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;"></span><span>获得直属下级业绩的
            <el-input v-model="subordinate_performance" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">%</template></el-input>
            (直属下级中去除掉，最高业绩)</span>
        </div>

        <div class="dqqy" style="margin: 5px 0; margin-left: 25px;" v-if="this.grade >= '2'">
          <span style="width: 150px;margin-left: 0px;"></span>
          <span style="margin-top: 10px;font-weight: 600;"></span><span>获得直属下下级业绩的
            <el-input v-model="lower_performance" placeholder="" style="width: 160px; margin-left: 10px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">%</template></el-input>
            (直属下级中去除掉，最高业绩)</span>
        </div>


        <div class="dqqy" style="margin-top: 40px ;" v-if="this.grade <= '4' && this.grade != 1">
          <span style="color: red;">*</span>
          <span style="width: 150px;font-weight: 700;font-size: 16px;">升级条件：</span>
          <span style="margin-right: 20px;">购买礼包，进行升级</span><el-button type="text"
            style="font-size: 14px;margin-top: -10px;" @click="chooseGoods" v-has="'选择礼包'" >选择商品</el-button>
        </div>

        <div class="dqqy" style="margin-top: 40px ;" v-if="this.grade == '5' || this.grade == '7'">
          <span style="color: red;">*</span>
          <span style="width: 150px;font-weight: 700;font-size: 16px;">升级条件：</span>
          <span>直推等级<span v-if="this.grade == '5'">四</span><span v-if="this.grade == '7'">六</span>人数 >= <el-input v-model="direct_level_num_one" placeholder=""
              style="width: 180px; margin: -10px 5px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
              <template slot="append">人</template></el-input></span>
        </div>

        <div class="dqqy" style="margin-top: 40px ;" v-if="this.grade == '5'|| this.grade == '7'">
          <span style="color: red;"></span>
          <span style="width: 155px;font-weight: 700;font-size: 16px;"></span>
          <span> 团队购买礼包商品数量 >= <el-input v-model="direct_level_goods_num_one" placeholder=""
              style="width: 180px; margin: -10px 5px;"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
              <template slot="append">件</template></el-input></span><el-button type="text"
            style="font-size: 14px;margin-top: -10px;" @click="chooseGoods" v-has="'选择礼包'" v-if="this.grade !=1 && this.grade < 5">选择商品</el-button>
        </div>

        <div class="dqqy" style="margin-top: 40px;display: flex;" v-if="this.grade == '6'">
          <div>
            <span style="color: red;">*</span>
            <span style="width: 150px;font-weight: 700;font-size: 16px;">升级条件：</span>
          </div>
          <div style="margin-left: 80px;">
            <div>
              <el-checkbox v-model="checked">方式一</el-checkbox>
              <el-checkbox v-model="checked2">方式二</el-checkbox>
            </div>
            <div v-if="checked">
              <div style="margin-top: 20px;">方式一</div>
              <div style="margin-top: 20px;">直推等级五人数 >= <el-input v-model="direct_level_num_one" placeholder=""
                  style="width: 180px; margin: -10px 5px;"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
                  <template slot="append">人</template></el-input></div>
              <div style="margin-top: 20px;">团队购买礼包商品数量>= <el-input v-model="direct_level_goods_num_one" placeholder=""
                  style="width: 180px; margin: -10px 5px;"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
                  <template slot="append">件</template></el-input></div>
            </div>
            <div v-if="checked2">
              <div style="margin-top: 20px;">方式二</div>
              <div style="margin-top: 20px;">购买礼包，进行升级 <el-button type="text" style="font-size: 14px;margin-top: -10px;"
                  @click="chooseGoods" v-has="'选择礼包'">选择商品</el-button></div>
              <div style="margin-top: 20px;">
                <span style="color: red;">*</span>
                <span style="width: 150px;font-weight: 700;font-size: 14px;">额外权益：</span>
              </div>
              <div style="margin-top: 20px;">享受额外下级链路 <el-input v-model="link_dividend_two" placeholder=""
                  style="width: 180px; margin: -10px 5px;"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
                  <template slot="append">元</template></el-input>额外分红</div>
              <div style="margin-top: 20px;">享受额外下级链路 <el-input v-model="link_weighting_dividend_two" placeholder=""
                  style="width: 180px; margin: -10px 5px;"
                  onkeyup="this.value=this.value.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
                  <template slot="append">元</template></el-input>加权分红</div>
            </div>
          </div>
        </div>

       
      </div>
    </div>
    <div class="save-cont" style="margin-left:-20px;">
      <!-- <div class="cancel-btn">取消</div> -->
      <div class="save-btn" @click="addSubmit()">保存</div>
    </div>

    <!-- 选择商品弹窗 -->
    <el-dialog title="选择商品" :visible.sync="centerDialogVisible" width="30%">
      <div class="serch">
        <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="name" placeholder="输入关键字"
          @keyup.enter.native="toSearch" size="small" type="text">
        </el-input><el-button type="primary" size="small" @click="toSearch" style="margin-left: 30px;">搜索</el-button>
      </div>
      <div class="main-cont bgwt mt10">
        <el-table :data="tableData" style="width: 100%" min-height="250" max-height="550" border
          @selection-change="handleSelectionChange" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
          v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)" :cell-style="grayBg">
          <el-table-column type="selection" width="55" :selectable="selectableFunc">
          </el-table-column>
          <el-table-column label="商品名称" width="350" align="left">
            <template slot-scope="scope">
              <div class="el_name" style="display: flex;">
                <div style="width:70px;height:70px;">
                  <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                    :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                </div>
                <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                  <p>{{ scope.row.goods_name }}</p>
                  <p class="mt10">{{ scope.row.count }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="goods_stock" label="总库存" align="center">
          </el-table-column>

        </el-table>
        <!-- 分页 -->
        <el-pagination hide-on-single-page background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 30]"
          :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addGoods" size="mini">确定添加</el-button>
      </span>
    </el-dialog>

    <!-- 选择自购优惠商品弹窗 -->
    <el-dialog title="选择自购优惠商品" :visible.sync="centerDialogVisible2" width="30%">
      <div class="serch">
        <el-input clearable class="inputsWidth" prefix-icon="el-icon-search" v-model="name" placeholder="输入关键字"
          @keyup.enter.native="toSearch" size="small" type="text">
        </el-input><el-button type="primary" size="small" @click="toSearch" style="margin-left: 30px;">搜索</el-button>
      </div>
      <div class="main-cont bgwt mt10">
        <el-table :data="tableData" style="width: 100%" min-height="250" max-height="550" border
          @selection-change="handleSelectionChange2" :header-cell-style="{ background: '#f3f8fe', color: '#606060' }"
          v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)" >
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column label="商品名称" width="350" align="left">
            <template slot-scope="scope">
              <div class="el_name" style="display: flex;">
                <div style="width:70px;height:70px;">
                  <el-image style="width: 100%;height: 100%;" :src="scope.row.goods_main_pic.split(',')[0]"
                    :preview-src-list="[scope.row.goods_main_pic.split(',')[0]]" class="el_img" />
                </div>
                <!-- <img :src="scope.row.goods_main_pic.split(',', 1)" class="el_img" /> -->
                <div style="width: 78%;margin-left: 10px; height: 70px;line-height: 70px;">
                  <p>{{ scope.row.goods_name }}</p>
                  <p class="mt10">{{ scope.row.count }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="goods_stock" label="总库存" align="center">
          </el-table-column>

        </el-table>
        <!-- 分页 -->
        <el-pagination hide-on-single-page background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 30]"
          :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addGoods('zh')" size="mini">确定添加</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { config, getConfig } from "@/api/customers"
import {
  goods_list,
} from "@/api/merchant/good.js"
export default {
  name: 'VueShopAdminGradeContent',
  props: ["grade"],
  watch: {
    // grade: {
    //   handler(newVal, oldVal) {
    //     this.getConfig()
    //   }
    // }
  },
  data() {
    return {
      checked: false,
      checked2: false,
      centerDialogVisible: false,//商品弹窗
      centerDialogVisible2: false,//选择自购优惠弹窗

      level: '',
      level_name: '',

      currentPage: 1,
      pagesize: 10000,
      loading: true,
      loading2: true,
      total: 0,
      tableData: [],
      name: '',
      multipleSelection: [],
      multipleSelection2: [],
      goodsList: [],
      goodsList2: [],
      package_id: '',
      self_purchase: '',
      direct_thrust: '',
      parallel_award: '',
      poor_prize: '',
      collaboration_award: "",
      subordinate_performance: "",
      lower_performance: "",
      link_dividend_two: "",
      link_weighting_dividend_two: "",
      direct_level_num_one:'',
      direct_level_goods_num_one:'',
      dividend_goods_id:'',
    };
  },

  mounted() {
    this.getConfig()
  },
  methods: {
    grayBg({ row }) {
      if (row.flag == 1) {
        return {
          background: '#edf2fc'
        }
      }
    },
    selectableFunc(row) {
      // console.log(row);
      return row.flag == 1 ? false : true;
    },
    del(val,type) {

      if(type =='zh'){
        const index = this.goodsList2.findIndex(item => item.goods_id == val.goods_id);
      this.goodsList2.splice(index, 1)
      return
      }

      // let index = this.goodsList.map((item,i)=>{
      //   if(item.goods_id==val.goods_id){
      //     return i
      //   }
      // });
      // index = index.filter(item=>item||item==0)
      // this.goodsList.splice(index[0],1)

      //用findIndex更加简单
      const index = this.goodsList.findIndex(item => item.goods_id == val.goods_id);
      this.goodsList.splice(index, 1)
    },
    addGoods(val) {
      // console.log(this.multipleSelection);
      if(val=='zh'){
        if (this.multipleSelection2.length == 0 ) {
        this.$message.info("请选择商品")
        return
      }
        this.goodsList2 = [...this.multipleSelection2]
      this.centerDialogVisible2 = false
      return
      }

      if (this.multipleSelection.length == 0 ) {
        this.$message.info("请选择商品")
        return
      }
      this.goodsList = [...this.multipleSelection]
      this.centerDialogVisible = false
    },
    //搜索
    toSearch() {
      this.currentPage = 1;
      this.getList();
    },
    searchOrder() {

    },
    //批量选择
    handleSelectionChange(val) {
      // let ids = []
      // val.forEach(item => {
      // 	ids.push(item.goods_unified_id)
      // })
      this.multipleSelection = val;
      // console.log(val);
    },

    handleSelectionChange2(val) {
      // let ids = []
      // val.forEach(item => {
      // 	ids.push(item.goods_unified_id)
      // })
      this.multipleSelection2 = val;
      // console.log(val);
    },
    //获取列表
    getList() {
      let data = {
        keyword: this.name,
        goods_source: '2',
        is_source: '0',
        page: this.currentPage,
        perPage: this.pagesize,
        is_gift_goods: 1,
        is_publish: 1
      }

      data = this.tools.filterParams(data);

      console.log(111111111111111111);
      goods_list(data).then(res => {
        this.loading = false;
        // this.tableData = res.data.list.filter(item =>
        //   item.is_publish == '1'
        // )
        this.tableData = res.data.list
        this.total = res.data.count
        console.log("tab", this.tableData);
        if ((this.package_id != '' || this.dividend_goods_id != '') && this.grade >= 2) {

          console.log(33333333333333333);

          let arr = this.package_id.split(',')
          let arr2 = this.dividend_goods_id.split(',')
          //  console.log("arr",arr);
          arr.map((item, index) => {
            this.tableData.map((i) => {
              if (i.goods_unified_id == item) {
                this.goodsList.push(i)
                //  console.log(213213213213213123);count
                this.goodsList = [...new Set(this.goodsList.map(JSON.stringify))].map(JSON.parse)
              } else {
                return
              }
            })
          })

          arr2.map((item, index) => {
            console.log(222222222222222222);
            
            this.tableData.map((i) => {
              if (i.goods_unified_id == item) {
                this.goodsList2.push(i)
                //  console.log(213213213213213123);count
                this.goodsList2 = [...new Set(this.goodsList2.map(JSON.stringify))].map(JSON.parse)
              } else {
                return
              }
            })
          })
        }
      })
      // console.log("tab2",this.tableData);

    },
    //分页
    handleSizeChange(size) {
      this.pagesize = size;
      // 掉接口
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      // 掉接口
      this.getList();
    },
    chooseGoods(val) {
      if(val == 'zh'){
        this.centerDialogVisible2 = true;
        return
      }
      this.centerDialogVisible = true;
      // this.getList()
    },
    getConfig() {
      getConfig({ level: this.grade }).then(async (res) => {
        if (res) {
          // console.log(res);
          this.self_purchase = res.data.self_purchase
          this.direct_thrust = res.data.direct_thrust
          this.parallel_award = res.data.parallel_award
          this.level_name = res.data.level_name
          this.poor_prize = res.data.poor_prize
          this.collaboration_award = res.data.collaboration_award
          this.subordinate_performance = res.data.subordinate_performance
          this.package_id = res.data.package_id //
          this.dividend_goods_id = res.data.dividend_goods_id
          this.lower_performance = res.data.lower_performance
          this.checked = res.data.conditional_one == 1 ? true :false
          this.checked2 = res.data.conditional_two ==1 ? true : false
          this.direct_level_num_one = res.data.direct_level_num_one
          this.direct_level_goods_num_one = res.data.direct_level_goods_num_one
          this.link_dividend_two = res.data.link_dividend_two
          this.link_weighting_dividend_two = res.data.link_weighting_dividend_two

          if (this.grade >= 2) {
            this.getList()
          }
        }
      })
    },
    addSubmit() {
      let id = this.goodsList.length > 0 ? this.goodsList.map(item => {
        return item.goods_sku_id
      }).join(",") : ''
      let id2 = this.goodsList.length > 0 ? this.goodsList.map(item => {
        return item.goods_unified_id
      }).join(",") : ''

      let id3 = this.goodsList2.length > 0 ? this.goodsList2.map(item => {
        return item.goods_unified_id
      }).join(",") : ''
      let data = {
        level: this.grade,
        self_purchase:this.self_purchase, 
        direct_thrust:this.direct_thrust,
        parallel_award:this.parallel_award,
        level_name:this.level_name, 
        poor_prize:this.poor_prize, 
        collaboration_award: this.collaboration_award,
        subordinate_performance:this.subordinate_performance, 
        lower_performance:this.lower_performance, 
        // conditional_one: this.conditional_one,
        package_sku_id: id,
        package_id: id2,
        dividend_goods_id:id3,
        direct_level_num_one: this.direct_level_num_one,
        direct_level_goods_num_one:this.direct_level_goods_num_one,
        // conditional_two:this.conditional_two,
        link_dividend_two:this.link_dividend_two ,
        link_weighting_dividend_two: this.link_weighting_dividend_two,
        conditional_one:this.checked,
        conditional_two:this.checked2,

      }
      // console.log(this.form);

      config(data).then(res => {
        if (res) {
          this.$message.success("保存成功！");
          this.getConfig()
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  color: rgb(173, 75, 205);
  font-size: 15px;
  font-weight: 700;
}

.main {
  padding: 30px;
  position: relative;
  background-color: #fff;
  padding-bottom: 40px;
  max-height: 1800px;
  min-height: 1000px;
  border-radius: 0px;
  padding-bottom: 80px;

  .goodsinfo {
    position: absolute;
    right: 20px;
    top: 540px;
    height: 500px;
    width: 615px;
    // background-color:red;
    .title {
      width: 615px;
      // margin:0 auto 
      text-align: center;
      font-weight: 700;

    }
  }

  .goodsinfo2 {
    position: absolute;
    right: 20px;
    top: 40px;
    height: 500px;
    width: 615px;

    // background-color:red;
    .title {
      width: 615px;
      // margin:0 auto 
      text-align: center;
      font-weight: 700;

    }
  }

  .djmc {
    display: flex;
    margin-bottom: 50px;
    // .listIcon1{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	// color:#ffcc33;
    // }
    // .listIcon2{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ffcc33 !important;
    // }
    // .listIcon3{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ff6666;
    // }
    // .listIcon4{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#00cc99;
    // }
    // .listIcon5{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#3399ff;
    // }
    // .listIcon6{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#cc33ff;
    // }
    // .listIcon7{
    // 	font-size: 16px;
    // 	font-weight: 700;
    // 	color:#ff5050;
    // }
  }

  .dqqy {
    display: flex;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>