<template>
    <div>
        <div class="wrapper-main pds">
            <div class="wrapper-cont">
                <div class="order-state">
                    <ul>
                        <li v-for="(tim, index) in operation_time" :key="index">
                            <div class="statestep">
                                <span class="circleNum" :class="tim.time ? ' ' : 'active'">{{ index + 1 }}</span>
                                <span class="statetext">{{ tim.name }}</span>
                                <span class="stateLine" :class="tim.time ? ' ' : 'active'"
                                    :style="index === operation_time.length - 1 ? ' display: none;' : 'display: block'"></span>
                            </div>
                            <div class="statetime">{{ tim.time }}</div>
                        </li>
                    </ul>
                </div>
                <el-card shadow="never" class="mb10">
                    <el-form ref="formsref" class="formtyle" :model="forms" label-width="90px">
                        <div class="order-infos">
                            <div class="order-infos-item">
                                <div class="or-state"><span></span>订单状态</div>
                                <el-form-item label="主订单号:">
                                    {{ forms.master_order_number }}
                                </el-form-item>
                                <el-form-item label="订单类型:">
                                    {{ forms.tag=='普通商品'?'实物商品': forms.tag}}
                                </el-form-item>
                                <div v-if="forms.afhalen!==1">
                                    <el-form-item label="订单号:">{{ forms.order_number }}</el-form-item>
                                <el-form-item label="快递公司:">{{ forms.logistics_name }}</el-form-item>
                                <el-form-item label="快递单号:">{{ forms.courier_code }}</el-form-item>
                                <el-form-item label="收货人:">
                                    <div class="orderUser">
                                        <div v-if="!submit.consignee_name.choose">{{ forms.consignee_name }}</div>
                                        <input type="text" v-model="submit.consignee_name.value" placeholder="输入收货人"
                                            v-if="submit.consignee_name.choose">
                                        <el-button type="primary" size="small"  v-if="((forms.status == 2) && !submit.consignee_name.choose)"
                                            @click="Change('consignee_name')">
                                            修改
                                        </el-button>
                                        <el-button type="primary" size="small"  v-if="((forms.status == 2) && submit.consignee_name.choose)"
                                            @click="submitFun('consignee_name')">
                                            保存
                                        </el-button>
                                        <el-button type="primary" size="small"  v-if="((forms.status == 2) && submit.consignee_name.choose)"
                                            @click="Change('consignee_name')">
                                            取消
                                        </el-button>
                                    </div>
                                </el-form-item>
                                <el-form-item label="联系方式:">
                                    <div class="orderUser">
                                        <div v-if="!submit.consignee_phone.choose">{{ forms.consignee_phone }}</div>
                                        <input type="text" v-model="submit.consignee_phone.value"
                                            v-if="submit.consignee_phone.choose" placeholder="输入联系方式">
                                        <el-button type="primary" size="small"  v-if="(forms.status == 2 && !submit.consignee_phone.choose)"
                                            @click="Change('consignee_phone')">
                                            修改
                                        </el-button>
                                        <el-button type="primary" size="small"  v-if="((forms.status == 2) && submit.consignee_phone.choose)"
                                            @click="submitFun('consignee_phone')">
                                            保存
                                        </el-button>
                                        <el-button type="primary" size="small"  v-if="((forms.status == 2) && submit.consignee_phone.choose)"
                                            @click="Change('consignee_phone')">
                                            取消
                                        </el-button>
                                    </div>
                                </el-form-item>
                                <el-form-item label="收货地址:">
                                    <div class="orderUser">
                                        <div v-if="!submit.address.choose">{{ (forms.consignee_provincial || '') +
                                                (forms.consignee_city
                                                    || '') + (forms.consignee_county || '') + (forms.consignee_street || '')
                                        }}
                                        </div>
                                        <el-cascader v-if="submit.address.choose" :options="areaSelectData"
                                            @change="handleChange" class="full-width" size="large"
                                            v-model="submit.address.value" placeholder="请选择您所在的城市" />
                                        <el-button v-if="(forms.status == 2 && !submit.address.choose)" size="small"
                                            type="primary" @click="Change('address')" plain>修改</el-button>

                                    </div>
                                    <div class="orderUser">
                                        <input v-if="submit.address.choose" type="text" v-model="submit.address.arrText"
                                            placeholder="输入详细地址">
                                        <el-button type="primary" size="small" v-if="((forms.status == 2) && submit.address.choose)"
                                            @click="submitFun('address')">
                                            保存
                                        </el-button>
                                        <el-button type="primary" size="small" v-if="((forms.status == 2) && submit.address.choose)"
                                            @click="Change('address')">
                                            取消
                                        </el-button>
                                    </div>
                                </el-form-item>
                                </div>
                                <div v-if="forms.afhalen==1">
                                <el-form-item label="自提点:">{{ forms.pick_json?forms.pick_json.province:''  }} {{ forms.pick_json?forms.pick_json.city:''  }} {{ forms.pick_json?forms.pick_json.district:''  }} {{ forms.pick_json?forms.pick_json.address:''  }} </el-form-item>
                                <el-form-item label="自提时间:">{{ forms.pick_time }}</el-form-item>
                                <el-form-item label="购买人:">{{ forms.get_account_user_info.mobile }}</el-form-item>  
                                </div>
                                <el-form-item label="退款原因:">{{ forms.orders_refund_reason }}</el-form-item>
                            </div>
                            <div class="order-infos-item" v-if="forms.tag != '普通商品'">
                                <div class="or-state"><span></span>买家及预约信息</div>
                                <el-form-item label="买家:"><span v-if="forms.get_account_user_info">{{
                                        forms.get_account_user_info.nickname
                                }}</span>
                                </el-form-item>
                                <el-form-item label="预约时间:"><span v-if="forms.appointment">{{
                                        forms.appointment.appointment_time
                                }}</span>
                                </el-form-item>
                                <el-form-item label="预约人数:"><span v-if="forms.appointment">{{ forms.appointment.number
                                }}</span></el-form-item>
                                <el-form-item label="消费金额:"><span>{{ forms.actual_payment }}</span></el-form-item>
                                <el-form-item label="预约手艺人:"><span v-if="forms.appointment">{{
                                        forms.appointment.technician_name
                                }}</span>
                                </el-form-item>
                            </div>
                            <div class="order-infos-item">
                                <div class="or-state"><span></span>付款信息</div>
                                <el-form-item label="运费:">
                                    {{ forms.freight }}
                                </el-form-item>
                                <el-form-item label="应付:">
                                    {{ forms.total_money }}
                                </el-form-item>
                                <el-form-item label="支付流水号:">
                                    {{ forms.pay_order_number }}
                                </el-form-item>
                                <el-form-item label="留言:">
                                    <div>{{ forms.buyers_remark }}</div>
                                </el-form-item>
                                <el-form-item label="备注:">
                                    <div>{{ forms.seller_remark }}</div>
                                    <div>
                                        <el-button size="small" type="primary" @click="addDesc" plain>
                                            {{ forms.seller_remark ? '修改备注' : '添加备注' }}</el-button>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </el-card>

                <el-card shadow="never" class="mb10" size="small" v-if="forms.tag != '普通商品' && forms.order_ganre!=1">
                    <div slot="header" class="clearfix">
                        <span>消费码</span>
                    </div>
                    <el-table :data="forms.verificat" style="width: 100%" size="small"
                        :header-cell-style="{ background: '#f3f8fe', color: '#606060' }">
                        <el-table-column prop="verification_code" label="消费码" width="180">
                        </el-table-column>
                        <el-table-column prop="is_use" label="状态" width="180">
                            <template slot-scope="scope">
                                <span v-if="scope.row.is_use == 1">未使用</span>
                                <span v-else-if="scope.row.is_use == 2">已使用</span>
                                <span v-else-if="scope.row.is_use == 3">已过期</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="scope.row.is_use == 1" @click="toUse(scope.row)">确认使用
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

                <div class="orders-table">
                    <div class="order-num">{{ created_at }} <span style="margin-left: 10px;">订单号：{{ forms.order_number
                    }}</span></div>
                    <el-table :data="tableData" border style="width: 100%" size="small">
                        <el-table-column prop="goods" label="商品信息" width="380px">
                            <template slot-scope="scope">
                                <div style="display: flex">
                                    <div style="margin:0 20px">
                                        <div style="width:70px;height:70px;">
                                            <el-image style="width: 100%;height: 100%;" :src="scope.row.master_map"
                                                class="el_img" :preview-src-list="[scope.row.master_map]" />
                                        </div>
                                        <!-- <img :src="scope.row.master_map" style="width: 70px;height: 70px" /> -->
                                    </div>
                                    <div class="namegood"> <el-tag type="danger" effect="dark" size="mini" style="margin-right: 10px;" v-if="scope.row.order_type==3">礼包商品</el-tag>{{ scope.row.goods_name }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku_desc" label="商品属性" align="center">
                        </el-table-column>
                        <el-table-column prop="goods_price" label="商品单价" align="center">
                        </el-table-column>
                        <el-table-column prop="goods_count" label="购买数量" align="center">
                        </el-table-column>
                        <el-table-column prop="discount_amount" label="优惠金额" align="center">
                        </el-table-column>
                        <el-table-column prop="freight" label="运费" align="center">
                        </el-table-column>
                        <el-table-column label="订单状态" align="center">
                            <template slot-scope="scope">
                                <p v-if="scope.row.status === 1">
                                    <span>待支付 </span>
                                    <br />
                                    <el-tag style="margin-top: 5px; cursor: pointer;" size="small"
                                        @click="closeOrders(item)">取消订单</el-tag>
                                </p>
                                <p v-else-if="scope.row.status === 2">
                                    <span>已支付</span>
                                    <br />
                                    <el-tag v-if="forms.tag == '普通商品' && forms.third_party_type == 2 && forms.afhalen!==1" style="margin-top: 5px; cursor: pointer;" size="small" @click="shipments()">发货</el-tag>
                                </p>
                                <p v-else-if="scope.row.status === 3">
                                    <span>待收货</span>
                                    <br />
                                    <el-tag style="margin-top: 5px; cursor: pointer;" size="small"
                                        @click="logisticsQuery()">物流查询</el-tag>
                                </p>
                                <p v-else-if="scope.row.status === 4">
                                    <span>已收货</span>
                                </p>
                                <p v-else-if="scope.row.status === 5">
                                    <span>已完成 </span>
                                </p>
                                <p v-else-if="scope.row.status === 6">
                                    <span>已关闭 </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_actual_payment" label="付款金额" align="center">
                        </el-table-column>
                        <el-table-column prop="goods_actual_payment" label="提货确认" align="center" v-if="forms.afhalen===1&&forms.status===2">
                            <template slot-scope="scope">
                                <el-button type="text" @click="toConfirm(scope.row)">确认提货
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <!--添加备注弹框-->
        <el-dialog title="添加备注" :visible.sync="addDescModel" width="25%">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="descInfo">
            </el-input>
            <div class="submitBtn">
                <el-button size="small" @click="addDescModel = false">取消</el-button>
                <el-button size="small" type="primary" @click="onSubmit">提交</el-button>
            </div>
        </el-dialog>
        <!--物流发货弹框-->
        <el-dialog title="物流发货" :visible.sync="logisticsModel" width="30%">
            <el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px"
                size="small">
                <el-form-item label="物流单号:" prop="oddNum">
                    <el-input class="inputWidth" v-model="logisticsForm.oddNum" placeholder="请填写物流单号"></el-input>
                </el-form-item>
                <el-form-item label="物流公司:" prop="company">
					<el-select v-model="logisticsForm.company">
						<el-option v-for="(item,index) in expressList" :key='index' :label="item.name" :value="item.name"></el-option>
					</el-select>
				</el-form-item>
            </el-form>
            <div class="submitBtn">
                <el-button size="small" @click="logisticsModel = false">取消</el-button>
                <el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
            </div>
        </el-dialog>
        <!--物流详情-->
        <el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
            <div v-if="activities.length > 0">
                <div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{ typename }} 快递单号：{{typenumber}}</div>
                <el-timeline>
                    <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
                        {{ activity.status }}
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
                暂无快递信息！
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

import {
    orderInfo,
    getExpressInfoList,
    dispatchBill,
    closeOrder,
    orderNumberQuery,
    createVerification,
    saveOrderAddress,
    confirmReceipt
} from '@/api/merchant/order.js'
import {
    saveRemark
} from '@/api/plat/order.js'
export default {
    props: {
        order_type: String
    },
    data() {
        return {
            expressList:[],
            master_order_number: '',
            areaSelectData: regionData,
            submit: {
                consignee_name: {
                    choose: false,
                    value: '',
                },
                consignee_phone: {
                    choose: false,
                    value: '',
                },
                address: {
                    choose: false,
                    value: '',
                    arr: [],
                    arrText: '',
                }
            },
            addDescModel: false,
            logisticsModel: false,
            logisticsDetailModel: false,
            invitation_code: '',
            nickname: '',
            descInfo: '', //备注信息
            forms: {},
            tableData: [],
            logisticsModel: false,
            logisticsForm: {
                oddNum: '',
                company: ''
            },
            logisticsRules: {
                oddNum: [{
                    required: true,
                    message: '请输入',
                    trigger: 'blur'
                },],
                company: [{
                    required: true,
                    message: '请输入',
                    trigger: 'blur'
                }]
            },
            operation_time: [],
            id: '',
            created_at: '',
            order_number: this.$route.query.id,
            type: this.$route.query.type,
            activities: [],
				typenumber:'',
            typename: '',
            fsnumShow: false
        }
    },
    mounted() {
        this.getInfo();
        this.getExpressInfoListData()
    },
    methods: {
        getExpressInfoListData(){
				let data={
					pitchon:1
				}
				getExpressInfoList(data).then(res=>{
					if (res.return_code === 'SUCCESS') {
						this.expressList = res.data;
					}
				})
			},
        Change(name) {
            this.$set(this.submit[name], 'choose', !this.submit[name].choose)
        },
        handleChange(e) {
            let arr = [CodeToText[e[0]], CodeToText[e[1]], CodeToText[e[2]]]
            this.$set(this.submit.address, 'arr', arr)
        },
        submitFun(name) {
            let data = {
                master_order_number: this.master_order_number,
            }
            if (name == "consignee_name") {
                data.consignee_name = this.submit.consignee_name.value;
            }
            if (name == "consignee_phone") {
                data.consignee_phone = this.submit.consignee_phone.value
            }
            // console.log(this.submit.address.arr);
            if (name == "address") {
                data.consignee_provincial = this.submit.address.arr[0];
                data.consignee_city = this.submit.address.arr[1];
                data.consignee_county = this.submit.address.arr[2];
                data.consignee_street = this.submit.address.arrText;
            }
            saveOrderAddress(data).then(res => {
                this.getInfo();
                this.Change(name)
            })
        },
        //订单详情
        getInfo() {
            let params = {
                order_number: this.order_number
            }
            params = this.tools.filterParams(params);
            orderInfo(params).then(res => {
                if (res.return_code === 'SUCCESS') {
                   
                    if(res.data.order_type==3){
                    this.tableData=res.data.order_goods_list
                    }else {
                    let dd = [];
                    dd.push(res.data.order_goods_info);
                    this.tableData = dd;
                    }
                   
                    this.tableData.forEach(item => {
                        item.status = res.data.status;
                        if (item.discount_amount && item.discount_amount > 0) {
                            item.discount_amounts = item.discount_amount;
                            this.fsnumShow = true
                        } else {
                            this.fsnumShow = false
                        }
                    });
                    this.forms = res.data;
                    this.operation_time = res.data.progress;
                    this.created_at = res.data.created_at;
                    this.master_order_number = res.data.master_order_number;
                }
            })
        },
        //核销消费码
        toUse(row) {
            this.tools.confirm("确定要使用此消费码？").then(res => {
                if (res) {
                    let data = {
                        verification_code: row.verification_code
                    }
                    createVerification(data).then(() => {
                        this.$message({
                            message: '消费码核销成功',
                            type: 'success'
                        });
                        this.getInfo();
                    })
                }
            })

        },
        toConfirm(row){
            this.tools.confirm("是否确定提货？").then(res => {
                if (res) {
                    // console.log(11111111111);
                    
                    let data = {
                        order_number: row.order_number
                    }
                    confirmReceipt(data).then(() => {
                        this.$message({
                            message: '提货成功',
                            type: 'success'
                        });
                        this.getInfo();
                    })
                }
            })
        },
        //发货
        shipments() {
            this.logisticsForm.oddNum = '';
            this.logisticsForm.company = '';
            this.logisticsModel = !this.logisticsModel;
        },

        //提交物流
        onSubmitLogistic(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //调接口
                    let data = {
                        order_number: this.order_number,
                        courierCode: this.logisticsForm.oddNum,
                        logisticsName: this.logisticsForm.company
                    }
                    data = this.tools.filterParams(data)
                    dispatchBill(data).then(res => {
                        this.logisticsModel = !this.logisticsModel;
                        this.getInfo();
                        this.logisticsForm.oddNum = '',
                            this.logisticsForm.company = ''
                    })

                } else {
                    return false;
                }
            });
        },
        //取消订单
        closeOrders() {
            this.tools.confirm("确定要取消此订单吗？").then(res => {
                if (res) {
                    //调接口
                    let data = {
                        order_number: this.order_number
                    }
                    closeOrder(data).then(() => {
                        this.getInfo();
                    })

                }
            })
        },

        //添加备注
        addDesc() {
            this.descInfo = '';
            this.addDescModel = !this.addDescModel
        },
        //提交新增备注
        onSubmit() {
            if (!this.descInfo) {
                this.$message.error("请输入备注信息！")
            } else {
                //调接口
                let data = {
                    order_number: this.order_number,
                    type: '2',
                    remark: this.descInfo
                }
                data = this.tools.filterParams(data);
                saveRemark(data).then(res => {
                    if (res.return_code === 'SUCCESS') {
                        this.addDescModel = !this.addDescModel
                        this.descInfo = '';
                        this.getInfo();
                    }
                })

            }
        },
        logisticsQuery() {
            this.logisticsDetailModel = !this.logisticsDetailModel;
            let params = {
                order_number: this.order_number
            }
            orderNumberQuery(params).then(res => {
                //物流信息
                if (res.data.list.length > 0) {
                    this.activities = res.data.list;
                    this.typename = res.data.typename;
                    this.typenumber=res.data.number
                }
            })

        }

    }

}
</script>

<style scoped lang="scss">
@import "@/assets/css/plat/allorderdetail.scss";

.pds {
    padding-bottom: 120px !important;
}

.order-infos-item::v-deep.el-form-item {
    margin-bottom: 0;
}

.order-infos {
    display: flex;
    justify-content: space-around;

    // align-items: center;
    .order-infos-item {
        flex: 1;
    }
}

.orderUser {
    display: flex;
    padding-bottom: 10px;
}
</style>
