<template>
	<div>
			<div class="wrapper-main">
					<div class="wrapper-cont">
							<el-card shadow="never">
									<el-row class="all_search_list" style="margin-bottom:0">								
											<div class="demo-input-suffix">
													收益人手机号：
													<el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
															v-model="search.sup_phone" placeholder="收益人手机号" @keyup.enter.native="toSearch"
															size="small" type="text">
													</el-input>
											</div>
											<div class="demo-input-suffix">
													购买人手机号：
													<el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
															v-model="search.sub_phone" placeholder="购买人手机号" @keyup.enter.native="toSearch"
															size="small" type="text">
													</el-input>
											</div>
										
											<div class="demo-input-suffix" style="margin-left: 70px;font-size: 13px;">
													购买时间：
													<el-date-picker style="width: 200px" size="small" v-model="due_dt" type="daterange"
															range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
															:default-time="['00:00:00', '23:59:59']" @change="changetimer"></el-date-picker>
											</div>
											<div class="demo-input-suffix" style="margin-left: 0px;font-size: 13px;">
													结算时间：
													<el-date-picker style="width: 200px" size="small" v-model="due_dt2" type="daterange"
															range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
															:default-time="['00:00:00', '23:59:59']" @change="changetimer2"></el-date-picker>
											</div>
											<div class="demo-input-suffix">
													<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
													<el-button size="small" @click="toResetOrder">重置</el-button>
											</div>
									</el-row>
							</el-card>
							<el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
									max-height="550" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
									v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
									element-loading-background="rgba(0, 0, 0, 0.2)">

									<el-table-column prop="mobile" label="收益人手机号" align="center"></el-table-column>
									<el-table-column prop="junior_mobile" label="购买人手机号" align="center">
										<template #default="{row}">
											<div>{{ row.junior_mobile }}</div>
											<div style="padding-left: 0px;"><el-button type="primary" size="mini"
                  @click="getPinfo(row)" style="padding: 5px !important;" v-has="'查看上级链路'">查看上级链路</el-button></div>
										</template>
									</el-table-column>
									<el-table-column prop="goods_number" label="礼包商品数量" align="center"></el-table-column>
									<el-table-column prop="calculate_profit" label="单个商品佣金" align="center"></el-table-column>
									<el-table-column prop="commission_money" label="总佣金" align="center"></el-table-column>
									<el-table-column prop="examined_at" label="购买礼包时间" sortable align="center"></el-table-column>
									<el-table-column prop="commission_state" label="结算状态" align="center">
										<template #default="{row}">
					<div v-if="row.commission_state==1" style="color: red;">待结算</div>
					<div v-if="row.commission_state==2" style="color: blue;">已结算</div>
				</template>
									</el-table-column>
									<el-table-column prop="settle_time" label="结算时间" sortable align="center"></el-table-column>
							</el-table>
							<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
									:page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
					</div>

 <!-- 弹窗 -->
 <el-dialog title="上级链路" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="conment">
        <div class="dj" v-for="(item,index) in parr" :key="index">
          {{  item }}
        </div>
      </div>
    </el-dialog>
			</div>
	</div>
</template>
<script>
	import {teamList} from "@/api/merchant/finance.js"
	import {getLink} from "@/api/merchant/users.js"
export default {
	data() {
			return {
					search: {
						sup_phone:'',
						sub_phone:'',
						settlement_start_time:'',
						buy_start_time:'',
						buy_end_time:'',
						settlement_end_time:'',
            status:''
					},
					tableData: [],
					total: 0,
					currentPage: 1, //初始页
					pagesize: 20, //每页的数据
					loading: true,
					due_dt:'',
					due_dt2:'',
					account_id:"",
					parr:[],
					centerDialogVisible:false
			}
	},
	mounted() {
			this.teamList()
	},
	methods: {
		getPinfo(row){
      this.centerDialogVisible = true
      this.getLink(row)
    },
		getLink(row){
      getLink({account_id:row.junior_account_id}).then(res=>{
        // console.log(2323213213);
        this.parr=res.data
        // console.log(res.data);
        
      })
    },
			//时间搜索
		changetimer(val) { //时间
			this.search.buy_start_time = val[0] / 1000;
			this.search.buy_end_time = val[1] / 1000;
		},
					//时间搜索
					changetimer2(val) { //时间
			this.search.settlement_start_time = val[0] / 1000;
			this.search.settlement_end_time = val[1] / 1000;
		},
		teamList() {
					let data=this.search;
					data.page=this.currentPage;
					data.perPage=this.pagesize;
					data.type=1
					teamList(data).then(res => {
							this.loading = false;
							this.tableData = res.data.list;
							this.total = res.data.count
							this.account_id=res.data.account_id;
							// console.log(res.data);
							
					})
			},
			//分页
			handleSizeChange(size) {
					this.pagesize = size;
					// 调接口
					this.teamList()
			},
			handleCurrentChange(currentPage) {
					this.currentPage = currentPage;
					// 调接口
					this.teamList()
			},
			//搜索
			searchOrder() {
					this.currentPage = 1;
					//调接口
					this.teamList()
			},
			toResetOrder() {
					this.currentPage = 1;
					this.search.sup_phone = '';
					this.search.sub_phone = '';
					this.search.settlement_start_time = '';
					this.search.buy_start_time = '';
					this.search.buy_end_time = '';
					this.search.settlement_end_time = '';
					this.search.status = '';
					this.teamList()
			}
	}
}
</script>
<style lang="scss" scoped>
::v-deep.el-select .el-input {
	width: 130px;
}
.conment {
    // background-color: red;
    // height: 100px;
    width: 100%;
    margin-top: -30px;
    .dj{
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: 1px #F2F2F6 solid;
      // background-color: blue;
      margin: 10px auto;
    }
  }
::v-deep.input-with-select .el-input-group__prepend {
	background-color: #fff;
}
</style>