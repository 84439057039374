<template>
	<div class="login-wrap">
	
		<div class="logopic">
			<img src="../../assets/img/tszjlogo.png" class="logoimg" />
			<span> | 更智能的美业店务管理系统</span>
		</div>
		<div class="login_counts">
			<div class="loginbox">
				<div class="loginmain">
					<div class="logintitle">密码登录</div>
					<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0px" class="ms-content">
						<el-form-item prop="username">
							<el-input v-model.trim="loginForm.username" clearable @input="phoneChange1" maxlength="11"
								placeholder="请输入手机号">
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" maxlength="16" show-password placeholder="请输入登录密码" clearable
								v-model.trim="loginForm.password">
							</el-input>
						</el-form-item>
						<div class="login-btn">
							<el-button type="primary" @click="submitLoginForm('loginForm')">登录</el-button>
						</div>
					</el-form>
					<div class="loginxy" @click="openAgreen">登录后表明您同意<span>登录服务协议</span></div>
				</div>

			</div>
			<div class="loginpic">
				<img  src="../../assets/img/loginactive.png"/>
				<div class="maintitles">更专业的美业智能管理系统</div>
				<div class="subtitles">路人变新客，新客变老客，老客带新客</div>
			</div>
		</div>
		<!-- <div class="logintxt">
			<div class="title">志同道合 合作共赢</div>
			<div class="text">一群人   一件事   一起拼   一起赢</div>
		</div>
		<div class="ms-login">
			<div class="logoTitle">平台登录</div>
			<div class="loginType">密码登录</div>
			<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0px" class="ms-content">
				<el-form-item prop="username">
					<el-input v-model.trim="loginForm.username" clearable @input="phoneChange1" maxlength="11"
						placeholder="请输入手机号">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input type="password" maxlength="16" placeholder="请输入登录密码" clearable v-model.trim="loginForm.password">
					</el-input>
				</el-form-item> 
				<div class="login-btn">
					<el-button type="primary" @click="submitLoginForm('loginForm')">登录</el-button>
				</div>
			</el-form>
			<div class="loginxy" @click="openAgreen">登录后表明您同意<span>牛店登录服务协议</span></div>
		</div> -->
		<!--弹框-->
		<el-dialog title="用户入驻协议" center :visible.sync="agreementModel" width="40%">
			<div class="agreeCont">
				<Editor v-model="agreenData" />
			</div>

		</el-dialog>

	</div>
</template>

<script>
import {
	logins,
	getProtocol,
	getTestAes
} from '@/api/plat/platlogin'
import { routerDispose } from '@/utils/recursion-router'
import Editor from '../common/loginEditor';
const sha256 = require('js-sha256').sha256;
import { isvalidPhone } from '@/utils/validate'
import permissionJson from '../../../static/plat.json'
var validPhone = (rule, value, callback) => {
	if (!value) {
		callback(new Error('请输入手机号'))
	} else if (!isvalidPhone(value)) {
		callback(new Error('请输入正确的11位手机号码'))
	} else {
		callback()
	}
};
export default {
	components: {
		Editor
	},
	data() {
		return {
			agreementModel: false,
			show: true,
			count: '',
			timer: null,
			agreenData: '',
			curIndex: 0,
			titleItem: [{
				id: 1,
				name: '密码登录'
			}, {
				id: 2,
				name: '验证码登录'
			}],
			loginForm: {
				username: '13269822845', //13269822845
				password: '123456', //123456789
			},

			rules: {
				username: [
					{ required: true, trigger: 'blur', validator: validPhone }
				],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}],
			},
		}
	},
	created() {

	},
	mounted() {
		this.$store.commit('LOGIN_OUT');
		localStorage.removeItem('dataset');
		localStorage.removeItem('head_portrait');
		localStorage.removeItem('terminal');
		localStorage.removeItem('shop_name');
		localStorage.removeItem('refreshToken')
		localStorage.removeItem('token');
		if (location.href.indexOf("#reloaded") == -1) {
			location.href = location.href + "#reloaded";
			window.location.reload();
		}
	},
	methods: {
		getAev() {
			let aa = JSON.stringify({
				account: '1213'
			});

			var a = this.aes.encrypt(aa);
			let data = {
				secretData: a
			}
			getTestAes(data).then(res => {

			})
		},
		openAgreen() {
			this.agreementModel = !this.agreementModel;
			this.getProtocolData()
		},
		getProtocolData() {
			let data = {
				type: 1
			}
			getProtocol(data).then(res => {
				this.agreenData = res.data;
			})
		},
		phoneChange1() {
			this.loginForm.username = this.loginForm.username.replace(/[^\d]/g, "");
			var reg = /^1[3456789]\d{9}$/;
		},
		selected(indx) {
			this.curIndex = indx;
		},
		submitLoginForm(loginForm) {
			this.$refs[loginForm].validate((valid) => {
				if (valid) {
					this.loginsub();
				} else {
					this.$message.error('请输入账号和密码');
					return false;
				}
			});
		},
		async loginsub() {
			var reg = /^1[3456789]\d{9}$/;
			if (this.loginForm.username.length >= 11 && !reg.test(this.loginForm.username)) {
				this.$message.error("请输入正确的手机号码格式");
				return;
			}
			if (this.loginForm.password.length < 6 || this.loginForm.password.length > 16) {
				this.$message.error('请输入6-16位密码');
				return;
			}
			let params;
			params = {
				account: this.loginForm.username,
				password: sha256(this.loginForm.password)
			}
			try {
				let data = await logins(params);
				localStorage.setItem('dataset', JSON.stringify(routerDispose(permissionJson)));//保存权限路由
				//localStorage.setItem('dataset',JSON.stringify(routerDispose(data.data.permission)));//保存权限路由
				let token = data.data.token;
				localStorage.setItem('dynamic', data.data.dynamic);
				localStorage.setItem('refreshToken', data.data.refreshToken);
				localStorage.setItem('terminal', data.data.terminal);
				localStorage.setItem('head_portrait', data.data.head_portrait);
				localStorage.setItem('shop_name', data.data.shop_name);
				this.$store.commit('LOGIN_IN', token);
				this.$router.replace('/home')
			} catch (e) {
				this.$message.error(e.return_msg)
			}


		}
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/login.scss";

.login-container {
        position: absolute;
        left: 60%;
        top: 50%;
        transform: translate(-50%, -50%);

        // padding: 0.8rem;
    }
	.login_main {
        background: #ffffff;
        border-radius: 14px;
    }
::v-deep.ql-container.ql-snow {
	border: none;
	padding: 0;
}

::v-deep.ql-toolbar.ql-snow {
	border: none;
	padding: 0;
}

.agreeCont {
	width: 100%;
	min-height: 27.5rem;
	overflow-y: scroll;
}

::v-deep.ql-editor {
	overflow-y: scroll;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}

::v-deep.ql-editor::-webkit-scrollbar {
	width: 0 !important
}

::v-deep.ql-editor {
	-ms-overflow-style: none;
}

::v-deep.ql-editor {
	overflow: -moz-scrollbars-none;
}
</style>
