<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<div class="order-state">
					<ul>
						<li v-for="(tim, index) in operation_time" :key="index">
							<div class="statestep">
								<span class="circleNum" :class="tim.time ? ' ' : 'active'">{{ index + 1 }}</span>
								<span class="statetext">{{ tim.name }}</span>
								<span class="stateLine" :class="tim.time ? ' ' : 'active'"
									:style="index === operation_time.length - 1 ? ' display: none;' : 'display: block'"></span>
							</div>
							<div class="statetime">{{ tim.time }}</div>
						</li>
					</ul>
				</div>
				<div class="order-info">
					<div class="order-info-item">
						<div class="or-state"><span></span>配送信息</div>
						<el-form ref="formstate" class="formstyle" :model="forms" label-width="90px">
							<el-form-item label="支付方式:">
								<span v-if="forms.payment_type == 0">未支付</span>
								<span v-else-if="forms.payment_type == 1">微信</span>
								<span v-else-if="forms.payment_type == 2">支付宝</span>
								<span v-else-if="forms.payment_type == 3">余额</span>
								<span v-else-if="forms.payment_type == 4">微信组合</span>
								<span v-else-if="forms.payment_type == 5">支付宝组合</span>
							</el-form-item>
							<el-form-item label="买家昵称:">
								{{ forms.consignee_name }}
							</el-form-item>
							<el-form-item label="手机号:">
								{{ forms.consignee_phone }}
							</el-form-item>
							<el-form-item label="收货信息:" v-if="forms.afhalen !== 1">
								{{ forms.address }}
							</el-form-item>
							<div v-if="forms.afhalen == 1">
								<el-form-item label="自提点:">{{ forms.pick_json ? forms.pick_json.province : '' }} {{
									forms.pick_json ? forms.pick_json.city : '' }} {{ forms.pick_json ? forms.pick_json.district : '' }} {{
									forms.pick_json ? forms.pick_json.address : '' }} </el-form-item>
								<el-form-item label="自提时间:">{{ forms.pick_time }}</el-form-item>
								<el-form-item label="购买人:">{{ forms.get_account_user_info.mobile }}</el-form-item>
							</div>
						</el-form>
					</div>
					<div class="order-info-item">
						<div class="or-state"><span></span>售后信息</div>
						<el-form ref="formpay" class="formstyle" :model="forms" label-width="90px">
							<el-form-item label="售后单号:">
								{{ forms.sale_order_number }}
							</el-form-item>
							<el-form-item label="订单编号:">
								{{ forms.order_number }}
							</el-form-item>
							<el-form-item label="售后类型:">
								{{ forms.oas_genre == 1 ? '退款' : '退货退款' }}

							</el-form-item>
							<el-form-item label="退款金额:">
								{{ forms.refund_money }}
							</el-form-item>
							<el-form-item label="退款原因:">
								{{ forms.pursue_reason }}
							</el-form-item>
							<el-form-item label="售后状态:">
								<p v-if="forms.oas_status === 1">
									<span>审核中</span>
									<br />
									<template v-if="forms.third_party_type == 2">
										<el-tag size="small" style="cursor: pointer;" v-if="forms.oas_status == 1"
											@click="noPassAduit()">拒绝</el-tag>
										<el-tag size="small" style="cursor: pointer;" v-if="forms.oas_status == 1"
											@click="passAduit()">同意</el-tag>
									</template>
								</p>
								<p v-if="forms.oas_status == 2">
									<span>审核驳回</span>
								</p>
								<p v-if="forms.oas_status == 3">
									<span>审核通过</span>
								</p>
								<p v-if="forms.oas_status == 4">
									<span>待收货</span>
									<br />
									<el-tag size="small" style="cursor: pointer;" @click="sureDeliveryOrder()">确认收货
									</el-tag>
									<el-tag size="small" style="cursor: pointer;" @click="logisticsQuery()">物流查询</el-tag>
								</p>
								<p v-if="forms.oas_status == 5">
									<span>已收货</span>
									<br />
									<el-tag size="small" style="cursor: pointer;" @click="sureRefund()">确认退款</el-tag>
								</p>
								<p v-if="forms.oas_status == 6">
									<span>退款中</span>
									<br />
									<el-tag size="small" style="cursor: pointer;" @click="sureToRefund()">确认退款</el-tag>
								</p>
								<p v-if="forms.oas_status == 7">
									<span>完成</span>
								</p>
								<p v-if="forms.oas_status == 8">
									<span>取消</span>
								</p>
								<p v-if="forms.oas_status == 9">
									<span>关闭</span>
								</p>
							</el-form-item>
							<el-form-item label="买家留言:">
								{{ forms.buyer_message }}
							</el-form-item>
							<el-form-item label="备注:">
								{{ forms.audit_leave_word }}
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="orders-table">
					<div class="order-num">{{ forms.created_at }} <span
							style="margin-left: 10px;">售后单号：{{ forms.sale_order_number }}</span></div>
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column prop="goods" label="商品信息" width="380px">
							<template slot-scope="scope">
								<div style="display: flex">
									<div style="margin:0 20px">
										<div style="width:70px;height:70px;">
											<el-image style="width: 100%;height: 100%;" :src="scope.row.master_map.split(',', 1)" class="el_img"
												:preview-src-list="[scope.row.master_map.split(',', 1)]" />
										</div>
										<!-- <img :src="scope.row.master_map.split(',',1)"
											style="width: 70px;height: 70px" /> -->
									</div>
									<div class="namegood">{{ scope.row.goods_name }}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="sku_desc" label="商品属性" align="center">
						</el-table-column>
						<el-table-column prop="goods_price" label="商品单价" align="center">
						</el-table-column>
						<el-table-column v-if="fsnumShow" prop="discount_amounts" label="优惠" align="center">
						</el-table-column>
						<el-table-column prop="goods_count" label="购买数量" align="center">
						</el-table-column>
						<el-table-column prop="freight" label="运费" align="center">
						</el-table-column>
						<el-table-column prop="goods_actual_payment" label="付款金额" align="center">
						</el-table-column>

					</el-table>
				</div>
			</div>
		</div>
		<!--物流发货弹框-->
		<el-dialog title="物流发货" :visible.sync="logisticsModel" width="40%" @close="closeForm">
			<el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px" size="small">
				<el-form-item label="物流单号:" prop="oddNum">
					<el-input class="" v-model="logisticsForm.oddNum" placeholder="请填写姓名"></el-input>
				</el-form-item>
				<el-form-item label="物流公司:" prop="company">
					<el-select v-model="logisticsForm.company">
						<el-option v-for="(item, index) in logisticData" :key="index" :value="item.id" :label="item.name"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="cancelLogistic('logisticsForm')">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
			</div>
		</el-dialog>
		<!--审核不通过-->
		<el-dialog title="添加备注" :visible.sync="refuseSaleAfterModel" width="25%">
			<el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="audit_leave_word">
			</el-input>
			<div class="submitBtn">
				<el-button size="small" @click="refuseSaleAfterModel = false">取消</el-button>
				<el-button size="small" type="primary" @click="refuseSaleAfterSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--确认退款-->
		<el-dialog title="同意退款售后" :visible.sync="agreeSaleAfterRefundModel" width="35%">
			<el-form v-model="agreeSaleAfterformSale" label-width="150px">
				<el-form-item label="售后处理:">同意售后申请</el-form-item>
				<el-form-item label="实际退款金额:">
					<el-input style="width: 300px !important;" onblur="if(value<=0)value=''"
						oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')"
						v-model="refund_money_sale" size="small"></el-input>
				</el-form-item>
				<el-form-item label="是否退运费:">
					<el-radio v-model="freightRadio" label="2">不退运费</el-radio>
					<el-radio v-model="freightRadio" label="1">退运费</el-radio>
				</el-form-item>
				<p style="color: #409EFF;padding-left: 30px;">
					此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
				</p>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterRefundModel = false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterRefundSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--退款-->
		<el-dialog title="同意退款售后" :visible.sync="agreeSaleAfterModel" width="35%">
			<el-form v-model="agreeSaleAfterform" label-width="150px">
				<el-form-item label="售后处理">同意售后申请</el-form-item>
				<el-form-item label="实际退款金额">
					<el-input style="width: 300px;" disabled v-model="refund_money" size="small"></el-input>
				</el-form-item>
				<!-- <p style="color: #409EFF;padding-left: 30px;">
					此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
				</p> -->
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterModel = false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--退货退款-->
		<el-dialog title="同意退货退款售后" :visible.sync="agreeSaleAfterAllModel" width="35%">
			<el-form v-model="agreeSaleAfterAllform" label-width="150px">
				<el-form-item label="售后处理：">同意退货退款申请</el-form-item>
				<el-form-item label="选择退货地址：">
					<el-select v-model="address_id" style="width: 300px;" size="small">
						<el-option v-for="(item, itemindex) in addressList" :key="itemindex" :value="item.shop_address_id"
							:label="item.province + item.city + item.district + item.full_address"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="实际退款金额：">
					<el-input style="width: 300px;" v-model="refund_money" size="small"></el-input>
				</el-form-item> -->
				<!-- <p style="color: #409EFF;padding-left: 30px;">
					此订单将执行订单原路退款并且关闭当前售后单，请确认并填写退款金额（不能大于订单实付款）
				</p> -->
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="agreeSaleAfterAllModel = false">取消</el-button>
				<el-button size="small" type="primary" @click="agreeSaleAfterAllSubmit">提交</el-button>
			</div>
		</el-dialog>
		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
			<div v-if="activities.length > 0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{ typename }} 快递单号：{{ typenumber }}</div>
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
						{{ activity.status }}
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	saleOrderInfo,
	refuseSaleAfter,
	passSaleAfter,
	getRefundAddress,
	ConfirmReceivingGoodsSaleAfter,
	refund,
	logisticsDetailModel,
	orderNumberQuery,
	saleOrderNumberQuery
} from '@/api/merchant/order.js'
import {
	regionData,
	CodeToText,
	TextToCode
} from "element-china-area-data";
export default {
	data() {
		return {
			operation_time: [],
			agreeSaleAfterAllform: {},
			agreeSaleAfterform: {},
			agreeSaleAfterformSale: {},
			refund_money_sale: '',
			agreeSaleAfterRefundModel: false,
			refuseSaleAfterModel: false,
			agreeSaleAfterModel: false,
			agreeSaleAfterAllModel: false,
			logisticsDetailModel: false,
			logisticsModel: false,
			logisticData: [],
			audit_leave_word: '',
			activeName: 'first',
			descInfo: '', //备注信息
			forms: {},
			formstate: {
				order_number: '',
				payment_type: '',
				nickname: '',
				phone: "",
				address: '',
				audit_leave_word: '',
				buyer_message: ''
			},
			formpay: {
				orderNo: '',
				saletype: "",
				saleMoney: '',
				reason: '',
				saleState: ''
			},
			allData: {},
			tableData: [],
			logisticsForm: {
				oddNum: '',
				company: ''
			},
			logisticsRules: {
				oddNum: [{
					required: true,
					message: '请输入',
					trigger: 'blur'
				},],
				company: [{
					required: true,
					message: '请选择',
					trigger: 'change'
				}]
			},
			logisticstableData: [{
				id: '56',
				name: '虚拟商品礼包',
				size: '无'
			},],
			multipleSelection: [],
			refund_money: '',
			sale_order_sn: '',
			address_detail: '',
			oas_numbers: '',
			address_id: '',
			addressList: [],
			created_at: '',
			order_after_sales_id: this.$route.query.id,
			shop_id: '',
			activities: [],
			typename: '',
			typenumber: '',
			oas_genre: '',
			order_number: '',
			freightRadio: '2',
			freight: '',
			input_money: '',
			fsnumShow: false
		}
	},
	mounted() {
		if (this.$route.query.id) {
			this.getInfo();
		}
	},
	methods: {
		//售后订单详情
		getInfo() {
			let data = {
				order_after_sales_id: this.order_after_sales_id
			}
			saleOrderInfo(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.operation_time = res.data.progress;
					this.order_number = res.data.order_number;
					this.shop_id = res.data.shop_id;
					this.order_after_sales_id = res.data.order_after_sales_id;
					this.forms = res.data;
					// this.formstate.nickname = res.data.consignee_name;
					// this.formstate.phone = res.data.consignee_phone;
					// this.formstate.order_number = res.data.order_number;
					this.input_money = ((res.data.actual_payment * 1) - (res.data.freight * 1)).toFixed(2);
					this.freight = res.data.freight;
					// this.formstate.payment_type = res.data.payment_type;
					this.forms.address = (res.data.consignee_provincial == null ? '' : res.data
						.consignee_provincial) + (res.data.consignee_city == null ? '' : res.data
							.consignee_city) + (res.data.consignee_county == null ? '' : res.data
								.consignee_county) + (res.data.consignee_street == null ? '' : res.data
									.consignee_street);
					// this.formstate.audit_leave_word = res.data.audit_leave_word;
					// this.formstate.buyer_message = res.data.buyer_message;

					// this.formpay.orderNo = res.data.sale_order_number;
					// this.formpay.saletype = res.data.oas_genre == 1 ? '退款' : '退货退款';
					this.oas_genre = res.data.oas_genre
					// this.formpay.saleMoney = res.data.refund_money;
					this.refund_money = res.data.apply_refund_money;
					// this.formpay.reason = res.data.pursue_reason;
					// this.formpay.saleState = res.data.oas_status;
					let dd = [];
					dd.push(res.data.order_goods_info);
					this.tableData = dd;
					this.tableData.forEach(item => {
						if (item.discount_amount && item.discount_amount > 0) {
							item.discount_amounts = item.discount_amount;
							this.fsnumShow = true;
							console.log("新增商品")
						} else {
							this.fsnumShow = false;
							console.log("122222")
						}

					})
				}

			})
		},
		noPassAduit() {
			this.refuseSaleAfterModel = true;
		},
		//审核不通过
		refuseSaleAfterSubmit() {
			let data = {
				order_after_sales_id: this.order_after_sales_id,
				audit_leave_word: this.audit_leave_word
			}
			data = this.tools.filterParams(data);
			refuseSaleAfter(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.refuseSaleAfterModel = !this.refuseSaleAfterModel;
					this.getInfo();
				}
			})
		},
		//退款审核通过
		passAduit() {
			if (this.oas_genre == 1) {
				this.agreeSaleAfterModel = !this.agreeSaleAfterModel;
			} else {
				this.getRefundAddressData();
				this.agreeSaleAfterAllModel = !this.agreeSaleAfterAllModel;
			}
		},
		//售后地址
		getRefundAddressData() {
			let data = {
				shop_id: this.shop_id,
				order_number: this.order_number
			}
			getRefundAddress(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.addressList = res.data;
				}
			})
		},
		sureToRefund() {
			this.refund_money_sale = this.input_money;
			this.freightRadio = this.freight * 1 > 0 ? '1' : '2'
			this.agreeSaleAfterRefundModel = !this.agreeSaleAfterRefundModel;
		},
		agreeSaleAfterRefundSubmit() {
			if (this.refund_money_sale * 1 > (this.input_money * 1).toFixed(2)) {
				this.$message.error("输入金额不能大于退款金额")
				return;
			}
			let data = {
				order_after_sales_id: this.order_after_sales_id,
				refund_money: this.refund_money_sale,
				is_freight: this.freightRadio
			}

			data = this.tools.filterParams(data);
			refund(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.getInfo()
					this.agreeSaleAfterRefundModel = !this.agreeSaleAfterRefundModel;
				}
			})
		},
		//同意退款
		agreeSaleAfterSubmit() {
			let data = {
				order_after_sales_id: this.order_after_sales_id,
				shop_id: this.shop_id,
				refund_money: this.formpay.saleMoney
			}
			// if (this.refund_money * 1 > this.formpay.saleMoney * 1) {
			// 	this.$message.error("输入金额大于退款金额")
			// 	return;
			// }
			data = this.tools.filterParams(data);
			passSaleAfter(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.getInfo()
					this.agreeSaleAfterModel = !this.agreeSaleAfterModel;
				}
			})
		},
		//同意退货退款
		agreeSaleAfterAllSubmit() {
			if (!this.shop_id) {
				this.$message.error('请选择退货地址');
				return false
			}
			let data = {
				order_after_sales_id: this.order_after_sales_id,
				shop_id: this.shop_id,
				shop_address_id: this.address_id
			}
			data = this.tools.filterParams(data);
			passSaleAfter(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.getInfo()
					this.agreeSaleAfterAllModel = !this.agreeSaleAfterAllModel;
				}
			})
		},
		//确认收获
		sureDeliveryOrder() {
			let data = {
				order_after_sales_id: this.order_after_sales_id
			}
			ConfirmReceivingGoodsSaleAfter(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.getInfo();
				}
			})
		},
		//确认退款
		sureRefund() {
			let data = {
				order_after_sales_id: this.order_after_sales_id,
				refund_money: this.formpay.saleMoney
			}
			refund(data).then(res => {
				if (res.return_code == 'SUCCESS') {
					this.getInfo()
				}
			})
		},
		//全选
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		closeForm() {
			this.logisticsModel = false;
			this.logisticsForm.oddNum = '',
				this.logisticsForm.company = ''
		},
		cancelLogistic(formName) {
			this.logisticsModel = false;
			this.$refs[formName].resetFields();
		},
		logisticsQuery() {
			this.logisticsDetailModel = !this.logisticsDetailModel;
			let params = {
				sale_order_number: this.formpay.orderNo
			}
			saleOrderNumberQuery(params).then(res => {
				//物流信息
				this.activities = res.data.list;
				this.typename = res.data.typename;
				this.typenumber = res.data.typenumber;
			})
		},
		//提交物流
		onSubmitLogistic(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					alert('submit!');
				} else {
					return false;
				}
			});
		}
	}

}
</script>

<style scoped lang="scss">@import "@/assets/css/plat/aftersaledetail.scss";</style>
