<template>
    <div class="editor">
        <div class="regu_table">
            <!-- <div class="sku_title">商品详情：</div> -->
            <div class="tables" style="padding:10px; margin-left:20px;">
                <div class="tables_con" style="max-height: 120px;">
                    <Toolbar
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    />
                </div>
                <div class="tables_con">
                    <Editor
                        style="width:100%; height:100%;"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onChange="onChange"
                        @onCreated="onCreated"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { uploadFile,fileUploading} from '@/api/img';
import { shopUpload } from '@/api/index';
export default {
    name:'Ueditor',
    components: { Editor, Toolbar },
    props: {
        content: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
        editor: null,
        html: "",
        toolbarConfig: {},
        editorConfig: {
            placeholder: "请输入内容...",
            MENU_CONF: {
                //   配置上传图片
                uploadImage: {
                    customUpload: async (file, insertFn) => {
                        let resultUrl = await this.upqiniu(file, file.name);
                        insertFn(resultUrl);
                    },
                    // server:"",
                    // fieldName: "file",
                    // meta: {},
                    // // 请求头
                    // headers: { token: localStorage.getItem("token") },
                    // timeout: 5 * 1000, // 5s 超时时间
                    // //选择文件时的类型限制，默认为['image/*'] 如不想限制，则设置为 []
                    // allowedFileTypes: ["image/*"],
                    // maxFileSize: 30 * 1024 * 1024, //1g //设置大点 不然图片过大会报错
                    // base64LimitSize: 1000000 * 1024, // 1g 以下插入 base64
                    // // 自定义插入图片
                    // customInsert(res, insertFn) {
                    //     // res 即服务端的返回结果
                    //     // console.log(res, "res");
                    //     // 从 res 中找到 url alt href ，然后插图图片
                    //     insertFn(url, alt, href);
                    // },
                },
                //   配置上传视频
                // uploadVideo: {
                //     server:"",
                //     fieldName: "file",
                //     meta: {},
                //     // 请求头
                //     headers: { token: localStorage.getItem("token")},
                //     timeout: 5 * 1000, // 5s 超时时间
                //     //选择文件时的类型限制，默认为['video/*'] 如不想限制，则设置为 []
                //     allowedFileTypes: ["video/*"],
                //     // 自定义插入视频
                //     customInsert(res, insertFn) {
                //         insertFn(url, alt, href);
                //     },
                // },
            },
        },
            mode: "default", // 'default' or 'simple'
            imgFileList: [], //上传图片
        };
    },
    created() {
        this.html = this.content;
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
            editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    mounted() {

    },
    methods: {
        upqiniu(file, name) {   //上传图片
            return new Promise((resolve) => {
                const isLt5M = file.size / 1024 / 1024 < 5;
                const isJPG = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'].includes(file.type);
                if (!isLt5M) {
                    this.$message.error("上传图片大小不能超过 5MB!");
                    return false
                }
                if(!isJPG){
                    this.$message.error("请上传正确的文件格式!");
                    return false
                }
                if(isLt5M && isJPG){
                    this.imgFileList.push(file);
                    var obj = new FormData();
                    obj.append('fileName', file)//当前为
                    // obj.append('classifyId','0')
                    shopUpload(obj).then(res => {
                        console.log(res);
                        resolve(res.data.resource_name);
                    }).catch(err=>{})
                }
            });
        },
        onChange(editor) {
            this.$emit("changeData", this.html);
        },
        onCreated(editor) {
            this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
            this.toolbarConfig = {
                toolbarKeys: [//自定义菜单选项
                    // 菜单 key
                    "headerSelect",
                    // // 分割线
                    "|",
                    // // 菜单 key
                    "bold",
                    "italic",
                    // // 菜单组，包含多个菜单
                    // {
                    //     key: "group-more-style", // 必填，要以 group 开头
                    //     title: "更多样式", // 必填
                    //     iconSvg: "<svg>123</svg>", // 可选
                    //     menuKeys: ["through", "code", "clearStyle"], // 下级菜单 key ，必填
                    // },
                    "underline",
                    "color",
                    "bgColor",
                    'uploadImage',
                    'editImage', 'viewImageLink', 'header1', 'header2', 'header3', 'header4', 'header5','imageWidth30', 'imageWidth50', 'imageWidth100',
                    'divider', 'insertLink',
                    'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight',
                    'insertTable','numberedList','undo','redo', 'editLink', 'deleteImage'
                    
                ],
                excludeKeys: [
                    // 'fullScreen',
                    // 'bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color', 'bgColor', 'fontSize', 'fontFamily',
                    // 'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight', 'insertImage', 'deleteImage',
                    // 'editImage', 'viewImageLink', 'imageWidth30', 'imageWidth50', 'imageWidth100', 'divider', 'emotion', 'insertLink', 'editLink',
                    // 'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1', 'header2', 'header3', 'header4', 'header5', 'todo',
                    // 'redo', 'undo', 'fullScreen', 'enter', 'bulletedList', 'numberedList', 'insertTable', 'deleteTable', 'insertTableRow',
                    // 'deleteTableRow', 'insertTableCol', 'deleteTableCol', 'tableHeader', 'tableFullWidth', 'insertVideo', 'uploadVideo', 'editVideoSize',
                    // 'uploadImage', 'codeSelectLang','group-more-style',
                    // "sub",
                    // "sup",
                ],
            };
        },
    }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped="scoped">
.editor{
    .regu_table{
        display: flex;
        .sku_title{
            display: flex;
            width: 80px;
            font-weight: bold;
        }
        .tables{
            // display: flex;
            width: 60%;
            // height: 580px;
            border:10px solid #f5f5f5;
            border-radius: 10px;
            .tables_con{

            }
        }
        /deep/.w-e-scroll{
            min-height: 420px !important;
        }
    }
}
</style>