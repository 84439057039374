<template>
	<div>
		<div class="wrapper-main">
            <div class="wrapper-cont">

			<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
				<el-form-item label="资讯名称" prop="title" style="width: 400px;">
					<el-input v-model="form.title" placeholder="请输入资讯名称" size="small"></el-input>
				</el-form-item>
                <el-form-item label="推广图" prop="images">
					<div class="avatar-uploader1" @click="uploadImg">
						<img v-if="form.images" :src="form.images" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</div>
				</el-form-item>
				
				<el-form-item label="排序值" prop="sort" style="width: 400px;">
					<el-input type="number" v-model="form.sort" :min="0" placeholder="请输入排序值" size="small"></el-input>
				</el-form-item>

				<el-form-item label="是否推荐" prop="is_mainly">
				     <el-radio v-model="form.is_mainly" label="1">不推荐</el-radio>
				     <el-radio v-model="form.is_mainly" label="2">推荐</el-radio>
				</el-form-item>
                <el-form-item label="正文内容" prop="content">
                    <div>
                        <!-- <editor apiKey="mfyuzxdpnb9l94ck5ga22ju405qie31n9a9yjd29zoqkxejz" :init="init" v-model="form.content"></editor> -->
						<Ueditor class="editor" v-if="content" ref="editorVue" :content="content" @changeData="hChangeData"/>
                    </div>
                </el-form-item>
			</el-form>
        </div>
    </div>

    <div class="save-cont">
        <div class="cancel-btn" @click="goback">返回</div>
        <div class="save-btn" v-if="consult_type == 'edit' || consult_type == 'add'" @click="addSubmit('form')">保存</div>
    </div>
    <div style="height: 130px;"></div>
		<!-- 选择图片弹窗 -->
		<ChooseImg :single="true" v-if="uploadFlag" @closeImg="chooseCancel" @okImg="chooseOk"></ChooseImg>
	</div>
</template>

<script>
	import ChooseImg from '../../../../../common/uploadImgs.vue';
    // import Editor from "@tinymce/tinymce-vue";
    // import Editor from '../../../../../common/Editor';
	import Ueditor from '../../../../../common/Ueditor.vue';
	const sha256 = require('js-sha256').sha256;
	import {
		isvalidPhone
	} from '@/utils/validate';
	import {
		platformAccountList,
		createPlatformAccount,
		getPlatformAccountInfo,
		revampAssignAccountInfo,
		remPlaformAccount,

        getInfo,
        save
	} from '@/api/plat/account';
	var validPhone = (rule, value, callback) => {
		if (!value) {
			callback(new Error('请输入电话号码'))
		} else if (!isvalidPhone(value)) {
			callback(new Error('请输入正确的11位手机号码'))
		} else {
			callback()
		}
	};
	export default {
		components: {
			ChooseImg,
            Ueditor,
		},
		data() {
			return {
				loading:true,
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				uploadFlag: false,
				title: '添加账号',
				isadd:false,
				form: {
					title: '',
					sort: '',
					images: '',
					is_mainly:'1',
                    content:''
				},
				rules: {
					title: [{
						required: true,
						message: '请填写名称',
						trigger: 'blur'
					}],
                    images: [{
						required: true,
						message: '请选择推广图',
						trigger: 'blur'
					}],
					is_mainly: [{
						required: true,
						message: '请选择是否推荐',
						trigger: 'blur'
					}],
					sort: [{
                        required: true,
                        message: '请填写排序',
                        trigger: 'blur',
                    }],
                    content: [{
                        required: true,
                        message: '请填写内容',
                        trigger: 'blur',
                    }],
				},
                consult_type:this.$route.query.type,
				consult_id:this.$route.query.consult_id,
                init: {
				language: "zh_CN",
				paste_data_images: false,
				content_style: "img {max-width:100%;}",
				// plugins:
				//   "lists image media table textcolor wordcount contextmenu paste",
				toolbar: "undo redo |  formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
				branding: false,
				min_height: 550,
				images_upload_handler: function (blobInfo, success, failure) {
					// console.log(blobInfo,success,failure,9999999)

					var data = {
						file: blobInfo.blob(),
					};
					fileUploading(data, {
						"Content-Type": "multipart/form-data",
					})
						.then((res) => {
							success(res.data.path);
						})
						.catch(() => {
							//  console.log(45454)
							failure("上传失败，请重新上传");
						});
				},
				},
				content:' ', //富文本数据
			}
		},
		mounted() {
			if(this.consult_type == 'edit' || this.consult_type == 'view'){
				this.getList();
			}
		},
		beforeDestroy() {
			const editor = this.$refs.editorVue.editor;
			if (editor == null) return;
				editor.destroy(); // 组件销毁时，及时销毁编辑器
		},
		methods: {
			hChangeData(editDataHtml) {
				// console.log("获取到富文本编辑器的内容",editDataHtml);
				// 获取最新的html数据
				this.content = editDataHtml;
				this.form.content = editDataHtml;
				// console.log(this.content);
			},
			uploadImg() {
				this.uploadFlag = !this.uploadFlag;
			},
			// 点击取消
			chooseCancel(val) {
				this.uploadFlag = val;
			},
			// 点击确定
			chooseOk(val) {
				this.form.images = val[0].resource_name;
				this.uploadFlag = false;
			},
			getList() {
				this.content = '';
				let data = {
					page: this.currentPage,
					perPage: this.pagesize,
                    consult_id:this.consult_id
				}
				delete data['keys_type'];
				data = this.tools.filterParams(data);
				getInfo(data).then(res => {
					this.loading=false;
					
					this.form.title = res.data.title;
					this.form.sort = res.data.sort;
					this.form.images = res.data.images;
					this.form.is_mainly = String(res.data.is_mainly);
					this.content = res.data.content;
                    this.form.content = res.data.content;
				})
			},

			//分页
			handleSizeChange(size) {
				this.pagesize = size;
				// 掉接口
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				// 掉接口
				this.getList()
			},
			//禁用启用
			toDel(row) {
				this.tools.confirm('确定要删除吗？').then(res => {
					if (res) {
						remPlaformAccount({account_platform_id:row.account_platform_id}).then(res=>{
							this.$message.success("删除成功！");
							this.getList()
						})
					}
				})
			},
			//编辑
			toEdit(row){
				this.$nextTick(()=>{
					this.$refs['form'].clearValidate();
				});
				this.isadd=false;
				this.title="编辑账号";
				this.account_platform_id=row.account_platform_id;
				this.getInfo(row.account_platform_id);
			},
			//获取信息
			getInfo(id){
				getPlatformAccountInfo({account_platform_id:id}).then(res=>{
					this.form=JSON.parse(JSON.stringify(res.data));
					this.form.role_id=this.form.role_id.split(',').map(Number);
					this.form.status=this.form.status.toString();
				})
			},


			//添加提交
			addSubmit(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						let data =JSON.parse(JSON.stringify(this.form));
						data.images=this.tools.filterImgPre(data.images);
						data=this.tools.filterParams(data);
						if(this.consult_id){
							data.consult_id=this.consult_id;
							save(data).then(res=>{
								this.$message.success("编辑成功！");
							})
						}else{
							save(data).then(res => {
								this.$message.success("添加成功！");
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
            goback() {
                this.$router.push({
                    path: '/dobusiness/information/informationList',
                    query: {
                        activeName: this.activeName,
                    },
                })
            },
		}
	}
</script>

<style lang="scss" scoped="scoped">
.wrapper-cont{
	overflow: hidden;
	height: 900px
}
	.avatar-uploader1 {
		border-radius: 4px 4px;
		border: 1px solid #ddd;
		background-color: #fff;
		width: 100px;
		overflow: hidden;
		height: 100px;
		line-height: 100px;

		.el-icon-plus {
			width: 100px;
			text-align: center;
			height: 100px;
			line-height: 100px;
			font-size: 24px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>
