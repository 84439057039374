import axios from '@/config/httpConfig'
import qs from  'qs'
//获取订单列表
export const rechargeAmount=(data)=> {
  return axios('post','/Order/shop/rechargeAmount', data);
}
//提现分页
export const getWxWithdrawalPageList=(params)=> {
	  return axios('get','Withdrawal/shop/getShopWithdrawalPageList', params)
}
//提现数据
export const getShopWithdrawalData=(params)=> {
	  return axios('get','Withdrawal/shop/getShopWithdrawalData', params)
}
//订单-虚拟订单查找支付状态
export const getVirtualOrderStatus=(params)=> {
	  return axios('get','/Order/shop/getVirtualOrderStatus', params)
}
//订单-虚拟订单列表
export const VirtualOrderList=(params)=> {
	  return axios('get','/Order/shop/VirtualOrderList', params)
}
//提现申请
export const shopApplyWithdrawal=(data)=> {
  return axios('post','Withdrawal/shop/shopApplyWithdrawal', data);
}
//获取微信扫码二维码
export const getAuthorizationUrl=(params)=> {
	  return axios('get','/Account/shop/getAuthorizationUrl', params)
}
//扫码确认支付
export const WithdrawalDispose=(data)=> {
  return axios('post','Withdrawal/shop/WithdrawalDispose', data);
}
//支付宝授权成功
export const aliPayWithdrawal=(data)=> {
  return axios('post','Withdrawal/shop/aliPayWithdrawal', data);
}
//获取微信扫码成功失败
export const getResultData=(params)=> {
	  return axios('get','Withdrawal/shop/getWithdrawalDisposeResults', params)
}
//推客佣金列表
export const getCommissionList=(params)=> {
	return axios('get','Twiker/platform/getCommissionList', params)
}
//获取支付宝扫码二维码
export const userLoginAuthorization=(params)=> {
	return axios('get','/Thirdparty/shop/userLoginAuthorization', params)
}

//获取奖金池统计数据
export const statistics=(params)=> {
	return axios('get','/Commission/platform/settlement/statistics', params)
}
//获取创始人奖列表
export const list=(params)=> {
	return axios('get','/Commission/platform/founder/award', params)
}
//获取直推奖列表
export const directList=(params)=> {
	return axios('get','/Commission/platform/direct/award', params)
}
//保存结算配置
export const config=(params)=> {
	return axios('post','/Commission/platform/settlement/config', params)
}
//保存结算配置
export const getConfig=(params)=> {
	return axios('get','/Commission/platform/settlement/config', params)
}
//保存提现配置
export const addWithdrawalConfig=(params)=> {
	return axios('post','/Withdrawal/platform/addWithdrawalConfig', params)
}
//获取提现配置
export const getWithdrawalConfigInfo=(params)=> {
	return axios('get','/Withdrawal/platform/getWithdrawalConfigInfo', params)
}

//获取合作奖列表
export const getRecordList=(params)=> {
	return axios('get','/Commission/platform/cooperate/award', params)
}

//获取平级奖&极差奖列表
export const teamList=(params)=> {
	return axios('get','/Commission/platform/level/award', params)
}

//获取团队奖列表
export const manageList=(params)=> {
	return axios('get','/Commission/platform/manage/list', params)
}

//获取分账用户信息
export const query_userinfo=(params)=> {
	return axios('get','/api/llpay/query_userinfo', params)
}

//获取手机验证码
export const openacct_apply_individual=(params)=> {
	return axios('post','/api/llpay/regphone_verifycode_apply ', params)
}

//提交手机验证码
export const regphone_verifycode_verify=(params)=> {
	return axios('post','/api/llpay/regphone_verifycode_verify', params)
}

//提交手机验证码
export const upload=(params)=> {
	return axios('post','/api/llpay/upload', params)
}

//获取银行列表 
export const bankcode=(params)=> {
	return axios('get','/api/llpay/bankcode', params)
}
//申请开户 
export const openacct_apply_enterprise=(params)=> {
	return axios('post','/api/llpay/openacct_apply_enterprise', params)
}

//申请设置密码 
export const apply_password_element=(params)=> {
	return axios('post','/api/llpay/apply_password_element', params)
}

//提交设置密码
export const openacct_verify_enterprise=(params)=> {
	return axios('post','/api/llpay/openacct_verify_enterprise', params)
}

//获取积分列表
export const accountCapitalLog=(params)=> {
	return axios('get','Account/platform/accountCapitalLog', params)
}

//获取分红奖列表
export const dividend=(params)=> {
	return axios('get','/Commission/platform/dividend/award', params)
}

//获取创始人平台奖列表
export const platform=(params)=> {
	return axios('get','/Commission/platform/founder/platform', params)
}