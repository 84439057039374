<template>
  <div>
    <div class="wrapper-main">
      <div class="model-content">
        <div class="main">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px">
            <el-form-item label="自提点名称" prop="name">
              <el-input size="small" class="inputwidth" v-model="ruleForm.name" placeholder="请输入自提点名称"></el-input>
            </el-form-item>
            <el-form-item label="自提点省市区:" prop="alladdress">
              <el-cascader style="width: 300px;" size="small" class="labelWidth" :options="options" collapse-tags
                :props="{ multiple: false }" clearable @change="handleChange" v-model="ruleForm.alladdress"
                ref="cascader">
              </el-cascader>
            </el-form-item>


            <el-form-item label="自提点详细地址:" prop="address">
              <el-input class="inputwidth" placeholder="请输入详细地址" v-model="ruleForm.address" size="small"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input size="small" class="inputwidth" maxlength="11" v-model="ruleForm.mobile" placeholder="请输入联系电话">
              </el-input>
            </el-form-item>

            <el-form-item label="自提时间" prop="time">
              <div>每周</div>
              <div class="week">
                <el-button type="text" @click="isShow = !isShow">添加星期</el-button>
                <div class="chooseWeek" v-if="isShow">
                  <div v-for="(item, index) in timeData"><el-checkbox v-model="item.isTrue" :disabled="item.disabled">{{
                    item.name }}</el-checkbox></div>
                  <div class="footer">
                    <el-button size="mini" @click="closeFn">取消</el-button>
                    <el-button type="primary" size="mini" @click="addFn">确认</el-button>
                  </div>
                </div>
                <div class="timeData" v-for="(item, index) in selectedTime" :key="index">
                  <div> <span>{{ item.name }} </span> <el-button type="text" style="margin-left: 50px;"
                      @click="delFn(index)">删除</el-button></div>
                  <el-time-picker is-range v-model="item.time_list" range-separator="至" start-placeholder="开始时间"
                    end-placeholder="结束时间" placeholder="选择时间范围" size="mini" format="HH:mm" style="width: 200px;"
                    @change="changeTime($event, index)" value-format="HH:mm">
                  </el-time-picker>
                </div>
              </div>

            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="save-cont">
      <div class="save-btn" @click="addressSubmit('ruleForm')">提交</div>
      <div class="cancel-btn" @click="cancelSubmit()">取消</div>
    </div>
  </div>
</template>

<script>
import {
  regionData,
  CodeToText,
  TextToCode
} from "element-china-area-data";
import {
  addAddress,
  pickaddressInfo,
  editAddress,
  picksaveAddress
} from "@/api/merchant/setting.js"
import {
  isvalidPhone
} from '@/utils/validate'
var validPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入电话号码'))
  } else if (!isvalidPhone(value)) {
    callback(new Error('请输入正确的11位手机号码'))
  } else {
    callback()
  }
};
export default {
  data() {
    return {
      options: regionData,
      ruleForm: {
        alladdress: [],
        name: '',
        mobile: '',
        province_id: '',
        city_id: '',
        district_id: '',
        address: '',
        time: '',
        // postcode: '',
        // is_delivery_default: '',
        // is_return_default: '',
      },

      rules: {
        name: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          trigger: 'blur',
          validator: validPhone
        }, //这里需要用到全局变量
        ],
        alladdress: [{
          type: 'array',
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        // time: [{
        //   required: true,
        //   message: '请选择时间',
        //   trigger: 'blur'
        // }],
      },
      address_id: this.$route.query.id,
      checkradio: '1',
      isShow: false,//选择弹窗
      timeData: [
        {
          name: '周一',
          week: 1,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周二',
          week: 2,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周三',
          week: 3,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周四',
          week: 4,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周五',
          week: 5,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周六',
          week: 6,
          isTrue: false,
          disabled: false,
        },
        {
          name: '周日',
          isTrue: false,
          week: 7,
          disabled: false,
        }
      ],
      selectedTime: []
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.addressInfo();
    }

  },
  beforeDestroy() {
    localStorage.removeItem('commodityId')
  },
  methods: {
    changeTime(e, index) {
      this.ruleForm.time = e

    },
    delFn(index) {
      this.timeData = this.timeData.map(item => {
        if (item.name == this.selectedTime[index].name) {
          item.isTrue = false;
          item.disabled = false;
        }
        console.log(item);
        return item
      })

      this.selectedTime.splice(index, 1)
    },
    closeFn() {
      this.isShow = false
    },
    addFn() {
      // console.log(this.timeData);

      // console.log(this.timeData);

      let arr = this.timeData.filter(item => item.isTrue && !item.disabled)
      this.timeData = this.timeData.map(item => {
        if (item.isTrue) {
          item.disabled = true
          return item
        } else {
          item.disabled = false
          return item
        }
      })
      this.selectedTime.push(...arr)

      this.isShow = false
    },
    handleChange(area) {

      if (area) {
        this.ruleForm.province_id = area[0];
        this.ruleForm.city_id = area[1];
        this.ruleForm.district_id = area[2];
        // this.ruleForm.address = CodeToText[this.ruleForm.province_id] + '-' + CodeToText[this.ruleForm
        //   .city_id] + '-' + CodeToText[this.ruleForm.district_id]

        this.ruleForm.province = CodeToText[this.ruleForm.province_id]
        this.ruleForm.city = CodeToText[this.ruleForm.city_id]
        this.ruleForm.district = CodeToText[this.ruleForm.district_id]

      }
    },
    addressInfo() {
      let data = {
        pick_address_id: this.$route.query.id
      }
      pickaddressInfo(data).then(res => {
        if (res.return_code == "SUCCESS") {
          this.ruleForm = res.data;
          this.ruleForm.alladdress = (res.data.province_id + ',' + res.data.city_id + ',' + res.data
            .district_id).split(",");
          this.selectedTime = res.data.week_json
          this.timeData = this.timeData.map(item => {
            res.data.week_json.map(i => {
              if (item.name == i.name) {
                item.isTrue = true
                item.disabled = true
              }
            })
            return item
          })
        }
      })
    },
    addressSubmit(ruleForm) {

      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          let data = { ...this.ruleForm }
          data.week_json = []
          this.selectedTime.map(item => {
            item.begin_time = item.time_list[0]
            item.end_time = item.time_list[1]
            data.week_json.push(item)
          })
          if (data.week_json.some(i => !i.begin_time)) {
            this.$message('请将自提时间填写完整！')
            return
          }
          // console.log( data.week_json,'data');
          data.pick_address_id = this.$route.query.id ? this.$route.query.id : ''
          data.week_json = JSON.stringify(data.week_json)
          if (data.pick_address_id) {
            picksaveAddress(data).then(res => {
              this.$message.success("编辑成功")
              this.$router.go(-1);//返回上一层
              localStorage.setItem('addressBack', 2)
            })
          } else {
            picksaveAddress(data).then(res => {
              this.$message.success("添加成功")
              this.$router.go(-1);//返回上一层
              localStorage.setItem('addressBack', 2)
            })
          }
        } else {
          return false;
        }
      });

    },
    cancelSubmit() {
      this.$router.go(-1);
      localStorage.setItem('addressBack', 2)
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.main {
  width: 100%;
  background: #F2F2F6;
  border-radius: 4px;
  padding: 20px 10px;

  .week {
    position: relative;

    .chooseWeek {
      z-index: 999999;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // height: 300px;
      padding: 0 20px;
      background-color: #fff;
      position: absolute;
      left: 70px;
      top: 15px;
    }
  }
}

.inputwidth {
  width: 300px;
}
</style>
