<template>
  <div>
    <div class="wrapper-main">
      <div class="main">
      
        <div class="all">
         
          
          <div style="display: flex;"><div style="font-weight: 700;">分红奖:</div> <div>
            <div> <el-button type="primary" size="small" style="margin-left: 50px;"  @click="add(lengthList[lengthList.length-1])">添加</el-button></div>
            <div style="margin-top: 20px;margin-left: 50px;" v-for="(item,index) in lengthList" :key="index">
              <span>团队业绩
            <el-input v-model="item.dividend_begin" placeholder="" style="width: 160px; margin-left: 0px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">元</template></el-input>
            </span> - <el-input v-model="item.dividend_end" placeholder="" style="width: 160px; margin-left: 0px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">元</template></el-input> 可获得团队业绩的 <el-input v-model="item.scale" placeholder="" style="width: 160px; margin-left: 0px;"
              oninput="value=value.replace(/^0|[^0-9]/g,'')"><template slot="append">%</template></el-input><el-button type="text" style="margin-left: 50px;" @click="save(item)">保存</el-button><el-button type="text" style="color: red;margin-left: 50px;" v-if="index == (lengthList.length-1)" @click="del(item,index)">删 除</el-button>
          </div>
          </div></div>

        </div>
     
    </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { getDividendConfig, saveConfig ,delDividendConfig} from "@/api/customers"
export default {
  components:{
    
  },
  data() {
    return {
     lengthList:[]
    }
  },
  mounted() {
    this.getDividendConfig()
  },
  methods: {
    add(val){
      this.lengthList.push({
        dividend_begin:val.dividend_end,
      })
    },
    save(item){
let data= {
  dividend_begin:item.dividend_begin,
  dividend_end:item.dividend_end,
  scale:item.scale,
  dividend_config_id:item.dividend_config_id
}

saveConfig(data).then(res=>{
  this.$message.success('保存成功！')
})
    },
    del(val,index){

      this.tools.confirm("确定删除？").then(res=>{
        
        if(res){
          if(!val.dividend_config_id){
        this.lengthList=this.lengthList.filter((item,i)=>i!=index)
        this.$message.success('删除成功！')
        return
      }
     
      delDividendConfig({dividend_config_id:val.dividend_config_id}).then(res=>{
        this.getDividendConfig()
        this.$message.success('删除成功！')
      })
        }
      })
      
    },
    getDividendConfig(){
      getDividendConfig({}).then(res=>{
       this.lengthList=res.data
      })
    }
   
  }
}
</script>
<style lang="less" scoped>
.wrapper-main {
  // background-color: red;
  // padding: 10px;

  .main {
    width: 100%;
    background-color: #F2F2F6;
    min-height: 700px;
    max-height: 1700px;
    border-radius: 10px;
    padding: 10px;
    .all{
      background-color: #fff;
      min-height: 700px;
      max-height: 1700px;
      padding: 20px;
    }
  }
}
</style>