<template>
	<div>
		<div class="wrapper-main">
			<div class="wrapper-cont">
				<el-tabs class="tabstyle" v-model="activeName" type="card" @tab-click="handleClick">
					<el-tab-pane name=""><span slot="label">全部订单</span></el-tab-pane>
					<el-tab-pane label="待支付" name="1"><span slot="label">待支付</span></el-tab-pane>
					<el-tab-pane label="待发货" name="2"><span slot="label">待发货</span></el-tab-pane>
					<el-tab-pane label="待收货" name="3"><span slot="label">已发货</span></el-tab-pane>
					<el-tab-pane label="待收货" name="4"><span slot="label">已收货</span></el-tab-pane>
					<el-tab-pane label="已完成" name="5"><span slot="label">已完成</span></el-tab-pane>
					<el-tab-pane label="交易关闭" name="6"><span slot="label">交易关闭</span></el-tab-pane>
				</el-tabs>
				<div class="order-search">
					<el-row class="all_search_list" style="margin-bottom:0">
						<div class="demo-input-suffix">
							<el-input placeholder="请输入内容" v-model="search.search_value" size="small"
								class="input-with-select"   @keyup.enter.native="searchOrder" clearable>
								<el-select v-model="search.search_type" slot="prepend" placeholder="请选择">
									<el-option label="全部" value=""></el-option>
									<el-option label="订单号" value="1"></el-option>
									<el-option label="姓名" value="2"></el-option>
									<el-option label="手机号" value="3"></el-option>
								</el-select>
							</el-input>
						</div>
						<!-- <div class="demo-input-suffix">
							<el-select class="w150" size="small" v-model="ordertypes"
								placeholder="请选择订单类型" clearable>
								<el-option label="实体订单" value="1-1"></el-option>
								<el-option label="虚拟订单" value="1-2"></el-option>
								<el-option label="会员订单" value="2-3"></el-option>
							</el-select>
						</div> -->
						<div class="demo-input-suffix" style="margin-left: 30px;font-size: 13px;">
							下单时间：
							<el-date-picker style="width: 350px" size="small" v-model="due_dt" type="daterange"
								range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
								@change="changetimer"></el-date-picker>
						</div>
						<div class="demo-input-suffix">
							<el-button type="primary" size="small" @click="searchOrder">查询</el-button>
							<el-button size="small" @click="toReset">重置</el-button>
						</div>
						<div class="demo-input-suffix" v-if="activeName != '4'">
							<el-button type="warning" size="small" @click="toExcel">导出订单</el-button>
						</div>
					</el-row>
				</div>
				<div class="tableMain">
					<el-table  :data="tableData"
						style="font-size: 12px;width: 100%;" max-height="550px" :default-expand-all="true" :header-cell-style="{background:'#f3f8fe',color:'#606060'}" v-loading="loading"  element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
						<div style="overflow: hidden">
							<el-table-column type="expand" width="1">
								<template slot-scope="props">
									<table border="1" class="tableStyle">
										<tbody>
											<tr v-for="(item,index) in props.row" :key="index">
												<td style=" width:30%;">
													<div style="display: flex; margin: 10px 0px;">
														<div style="margin:auto 10px" v-if="item.order_goods_info != null">
															  <div style="width:70px;height:70px;" >
																<el-image style="width: 100%;height: 100%;" :src="item.order_goods_info.master_map" class="el_img" :preview-src-list="[item.order_goods_info.master_map]"/>
															</div>
														</div>
														<div  v-if="item.order_goods_info != null">
															<div class="namegood">
																<span  v-if="item.tag && item.tag != '普通商品'">{{item.tag}}</span>
																{{item.order_goods_info.goods_name }}
															</div>
															<div class="namegood" style="margin-top:5px">
																子单号：{{item.order_goods_info.order_number }}</div>
															<div class="pricegood" style="margin-top:5px">
																数量：{{item.order_goods_info.goods_count}}
																<span
																	style="margin-left: 50px;">￥{{item.order_goods_info.goods_price }}</span>
																<span style="margin-left: 50px; color:cornflowerblue;">来源：{{item.third_party_type == '2'?'商户':item.third_party_type == '1' ? '库宝宝':'平台'}}</span>
															</div>
														</div>
													</div>
												</td>
												<td style="width: 10%;padding-left:15px">
													<p>应付：{{item.total_money}}</p>
													<p>运费：{{item.freight}}</p>
													<p style="color: #F56C6C;" v-if="item.status!=1&&item.status!=6">实付：{{props.row.status}}{{item.actual_payment}}</p>
												</td>
												<td style="width:10%;text-align: center">
													<p>{{item.consignee_name}}</p>
													<p>{{item.consignee_phone}}</p>
												</td>
												<td style="width:10%;text-align: center">
													<p>{{item.get_account_user_info.nickname}}</p>
													<p>{{item.get_account_user_info.mobile}}</p>
												</td>
												<td style="width:10%;text-align: center">
													<span v-if="item.payment_type==0">未支付</span>
													<span v-else-if="item.payment_type==1">微信</span>
													<span v-else-if="item.payment_type==2">支付宝</span>
													<span v-else-if="item.payment_type==3">余额</span>
													<span v-else-if="item.payment_type==4">微信组合</span>
													<span v-else-if="item.payment_type==5">支付宝组合</span>
												</td>
												<td style="width:5%;text-align: center">
													<span v-if="item.courier_code">快递配送</span>
													<span v-else>/</span>
												</td>
												<td style="width:10%;text-align: center">
													<p v-if="item.status === 1">
														<!-- 待付款 -->
														<span>待支付 </span>
														<br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="closeOrders(item)">取消订单</el-tag>
													</p>
													<p v-else-if="item.status === 2">
														<!-- 待发货 -->
														<span>已支付</span>
														<br />
														<template v-if="item.third_party_type == '2'&&item.afhalen!==1">
															<el-tag v-if="item.tag == '普通商品'" style="margin-top: 5px; cursor: pointer;" size="small" @click="shipments(item,'one')">发货</el-tag>
														</template>
													
													</p>
													<p v-else-if="item.status === 3">
														<span>待收货 </span>
														<br />
														<el-tag style="margin-top: 5px; cursor: pointer;" size="small"
															@click="logisticsQuery(item)">物流查询</el-tag>
													</p>
													<p v-else-if="item.status === 4">
														<span>已收货 </span>
													</p>
													<p v-else-if="item.status === 5">
														<span>已完成 </span>
													</p>
													<p v-else-if="item.status === 6">
														<span>已关闭 </span>
													</p>
												</td>
												<td style="width:5%;text-align: center">
													{{item.seller_remark}}
													<!-- {{item.buyers_remark}} -->
												</td>
												<td style="width:10%;text-align: center">
													<el-button type="text" size="small" @click="orderDetail(item)">
														查看详情</el-button>
												</td>
											</tr>
										</tbody>
									</table>
								</template>
							</el-table-column>
						</div>
						<el-table-column label="商品信息" min-width="275">
							<template slot-scope="scope" style="padding: 10px 0;">
								<div class="aaa">
									<span class="orderTime">{{scope.row[0].created_at}}</span>
									主单号：<span style="color: #1d8ce0;">{{ scope.row[0].master_order_number}}</span>
									<el-tag
										v-if="scope.row.length>1 && scope.row.map(item => item.status === 2).toString().includes('true')"
										effect="dark" style="margin-left: 5px; cursor: pointer;" size="small"
										@click="shipments(scope.row,'more')">合并发货</el-tag>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="付款" align="center">
						</el-table-column>
						<el-table-column label="收件人" align="center">
						</el-table-column>
						<el-table-column label="买家信息" align="center">
						</el-table-column>
						<el-table-column label="支付方式" align="center">
						</el-table-column>
						<el-table-column label="配送方式" align="center">
						</el-table-column>
						<el-table-column label="订单状态" align="center">
						</el-table-column>
						<el-table-column label="备注" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
						</el-table-column>
					</el-table>
					<!-- 分页 -->
					<el-pagination class="pagecurrent" hide-on-single-page background  @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10,20,30,50]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
			</div>
		</div>
		
		<!--物流发货弹框-->
		<el-dialog title="物流发货" :visible.sync="logisticsModel" width="30%">
			<el-form ref="logisticsForm" :model="logisticsForm" :rules="logisticsRules" label-width="100px"
				size="small">
				<el-form-item label="物流单号:" prop="oddNum">
					<el-input class="inputWidth" v-model="logisticsForm.oddNum" placeholder="请填写物流单号"></el-input>
				</el-form-item>
				<el-form-item label="物流公司:" prop="company">
					<el-select v-model="logisticsForm.company">
						<el-option v-for="(item,index) in expressList" :key='index' :label="item.name" :value="item.name"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="submitBtn">
				<el-button size="small" @click="logisticsModel=false">取消</el-button>
				<el-button size="small" type="primary" @click="onSubmitLogistic('logisticsForm')">提交</el-button>
			</div>
		</el-dialog>
		<!--物流详情-->
		<el-dialog title="物流详情" :visible.sync="logisticsDetailModel" width="40%">
			<div v-if="activities && activities.length>0">
				<div style="font-size: 16px;margin-bottom: 20px;color: #409EFF;">快递名称：{{typename}} 快递单号：{{typenumber}}</div>
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
						{{activity.status}}
					</el-timeline-item>
				</el-timeline>
			</div>
			<div v-else style="height: 100px; display: flex;align-items: center;justify-content: center;">
				暂无快递信息！
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import {
		orderList,
		getExpressInfoList,
		dispatchBill,
		closeOrder,
		orderNumberQuery,
		orderExport
	} from "@/api/merchant/order.js"
	export default {
		data() {
			return {
				account_id: this.$route.query.account_user_id || '',
				loading:true,
				activeName: '',
				ordertypes:'',
				search: {
					search_type: '',
					search_value: '',
					begin_time: '',
					end_time: '',
					status: '',
					
				},
				due_dt: '',
				tableData: [],
				total: 0,
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				logisticsModel: false,
				logisticsForm: {
					oddNum: '',
					company: ''
				},
				logisticsRules: {
					oddNum: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}, ],
					company: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}]
				},
				order_number: '',
				orderTypeNum: {
					close_number: 0,
					completed: 0,
					refund_number: 0,
					to_be_deliverd_number: 0,
					to_be_received: 0,
					unpaid_number: 0,
					whole_order_number: 0,
				},
				order_status: "",
				logisticsDetailModel: false,
				activities: [],
				typename: '',
				typenumber:'',
				expressList:[],
			}
		},
		created() {
			if (this.$route.query.name) {
				this.activeName = this.$route.query.name;
				this.search.status = this.$route.query.name;
			}
		},
		mounted() {
			this.getList();
			this.getExpressInfoListData()
		},
		methods: {
			toExcel(){				
				let a = document.createElement('a'); //创建a标签
				a.href = 'https://ssjf.api.aissyun.com/Order/platform/orderExport?export=1'; //标签路径
				a.download = '订单'; //下载文件名称
				a.click(); //模拟点击
				a.remove()
			},
			getExpressInfoListData(){
				let data={
					pitchon:1
				}
				getExpressInfoList(data).then(res=>{
					if (res.return_code === 'SUCCESS') {
						this.expressList = res.data;
					}
				})
			},
			//订单列表
			getList() {
				let params = this.search;
				params.account_id=this.account_id;
				params.order_type = this.ordertypes.split('-')[0];
				params.order_ganre = this.ordertypes.split('-')[1];
				params.get_terminal_tag = 'home'
				params.page = this.currentPage;
				params.perPage = this.pagesize;
				params = this.tools.filterParams(params);
				orderList(params).then(res => {
					this.loading=false;
					this.total = res.data.count;
					this.tableData = res.data.list;
					console.log(this.tableData,"this.tableData")
				})
			},

			handleClick(val) {
				this.currentPage = 1;
				this.search.search_type = '';
				this.search.search_value = '',
				this.search.begin_time = '';
				this.search.end_time = '';
				this.due_dt = '';
				this.ordertypes='';
				this.search.status = val.name;
				this.getList()
			},
			searchOrder() {
				this.currentPage = 1;
				this.getList()
			},
			toReset() {
				this.currentPage = 1;
				this.search.search_type = '';
				this.search.search_value = '',
				this.search.begin_time = '';
				this.search.end_time = '';
				this.search.status = '';
				this.ordertypes='';
			
				this.due_dt = '';
				this.getList()
			},
			//时间选择
			changetimer(val) { //时间
				if (val) {
					this.search.begin_time = val[0] / 1000;
					this.search.end_time = val[1] / 1000;
				} else {
					this.search.begin_time = '';
					this.search.end_time = '';
				}
			},
			//订单详情
			orderDetail(row) {
				this.$router.push({
					path: '/ordergoodadmin/shopmallorder/shopmallorderdetail',
					query: {
						id: row.order_number
						
					}
				})
			},

			handleSizeChange(size) {
				this.pagesize = size;
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.currentPage = currentPage;
				this.getList()
			},
			//发货
			shipments(row, type) {
				if (type == 'more') {
					let nums = []
					row.forEach(item => {
						nums.push(item.order_number)
					});
					this.order_number = nums.toString()
				} else if (type == 'one') {
					this.order_number = row.order_number
				}
				this.logisticsForm.oddNum = '';
				this.logisticsForm.company = '';
				this.logisticsModel = !this.logisticsModel;
			},
			//取消订单
			closeOrders(row) {
				this.tools.confirm("确定要取消此订单吗？").then(res => {
					if (res) {
						//调接口
						let data = {
							master_order_number: row.master_order_number
						}
						closeOrder(data).then(() => {
							this.getList()
						})
					}
				})
			},
			//提交物流
			onSubmitLogistic(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//调接口
						let data = {
							order_number: this.order_number,
							courierCode: this.logisticsForm.oddNum,
							logisticsName: this.logisticsForm.company
						}
						data = this.tools.filterParams(data)
						dispatchBill(data).then(res => {
							this.logisticsModel = !this.logisticsModel;
							this.getList();
							this.logisticsForm.oddNum = '',
								this.logisticsForm.company = ''
						})
					} else {
						return false;
					}
				});
			},
			logisticsQuery(row) {
				this.logisticsDetailModel = !this.logisticsDetailModel;
				let params = {
					order_number: row.order_number
				}
				orderNumberQuery(params).then(res => {
					//物流信息
					if (res.data.list.length > 0) {
						this.activities = res.data.list;
						this.typename = res.data.typename;
						this.typenumber=res.data.number
					}

				})
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	@import "@/assets/css/plat/order.scss";

	::v-deep.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0 0 0;
	}

	::v-deep.el-table .el-table_1_column_2 .cell {
		width: 32.25rem !important;
	}
	::v-deep.input-with-select .el-select .el-input {
		width: 130px !important;
	}
	::v-deep.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}


	::v-deep.el-table__expand-icon>.el-icon {
		display: none;
	}

	::v-deep.el-table .cell {
		overflow: visible !important;
	}
	::v-deep .el-table .el-table__cell{
		position: inherit;
	}
	.tableMain::v-deep.aaa {
		width: 500px;
	}
</style>
