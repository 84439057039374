<template>
	<div>
			<div class="wrapper-main">
					<div class="wrapper-cont">
							<el-card shadow="never">
								<el-row class="all_search_list" style="margin-bottom:0">								
											<div class="demo-input-suffix">
													收益人手机号：
													<el-input clearable class="inputsWidth" prefix-icon="el-icon-search"
															v-model="search.sup_phone" placeholder="收益人手机号" @keyup.enter.native="toSearch"
															size="small" type="text">
													</el-input>
											</div>
											
											<div class="demo-input-suffix" style="margin-left: 0px;font-size: 13px;">
													结算时间：
													<el-date-picker style="width: 200px" size="small" v-model="due_dt2" type="daterange"
															range-separator="至" start-placeholder="开始日期" clearable end-placeholder="结束日期"
															:default-time="['00:00:00', '23:59:59']" @change="changetimer2"></el-date-picker>
											</div>
											<div class="demo-input-suffix">
													<el-button type="primary" size="small" @click="searchOrder">搜索</el-button>
													<el-button size="small" @click="toResetOrder">重置</el-button>
											</div>
									</el-row>
							</el-card>
							<el-table class="mt10" ref="multipleTable" :data="tableData" size="small" border tooltip-effect="dark"
									max-height="550" style="width: 100%" :header-cell-style="{background:'#f3f8fe',color:'#606060'}"
									v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading"
									element-loading-background="rgba(0, 0, 0, 0.2)">
									<el-table-column prop="mobile" label="受益人手机号" align="center"></el-table-column>
									<el-table-column prop="amount" label="团队业绩" align="center">
									</el-table-column>
									<el-table-column prop="commission_interest_rate" label="佣金比例" align="center"></el-table-column>
									<el-table-column prop="deduct_money" label="直属下级扣除分红奖总额" align="center"></el-table-column>
									<el-table-column prop="commission_total_money" label="分红奖总额" align="center"></el-table-column>
									<el-table-column prop="commission_money" label="可得分红奖金额" align="center"></el-table-column>
									<el-table-column prop="commission_state" label="结算状态" align="center">
											<template slot-scope="scope">
													<span style="color: #10c34b;" v-if="scope.row.commission_state==2">已结算</span>
													<span style="" v-if="scope.row.commission_state==1">待结算</span>
											</template>
									</el-table-column>
									<el-table-column prop="settle_time" label="结算时间" sortable align="center"></el-table-column>
									<!-- <el-table-column label="操作" align="center">
													<template slot-scope="scope">
															<el-button type="text" size="small" @click="toExamine(scope.row)" v-has="'查看'">查看</el-button>
													</template>
											</el-table-column> -->
							</el-table>
							<el-pagination class="pagecurrent" hide-on-single-page background @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
									:page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
					</div>

			</div>
	</div>
</template>
<script>
	import {
		dividend
} from "@/api/merchant/finance.js"
export default {
	data() {
			return {
					search: {
							phone:'',
							level:'',
							start_time:'',
							end_time:'',
					},
					tableData: [],
					total: 100,
					currentPage: 1, //初始页
					pagesize: 10, //每页的数据
					loading: true,
					due_dt:'',
			}
	},
	mounted() {
			this.getList()
	},
	methods: {
			//时间搜索
		changetimer(val) { //时间
			this.search.start_time = val[0] / 1000;
			this.search.end_time = val[1] / 1000;
		},
			getList() {
					let data=this.search;
					data.page=this.currentPage;
					data.per_page=this.pagesize;
					// data.account_user_id=107
					dividend(data).then(res => {
							this.loading = false;
							this.tableData = res.data.list;
							// console.log(213131323123);
							
							// console.log(res.data);
							this.total = res.data.count
					})
			},
			//分页
			handleSizeChange(size) {
					this.pagesize = size;
					// 调接口
					this.getList()
			},
			handleCurrentChange(currentPage) {
					this.currentPage = currentPage;
					// 调接口
					this.getList()
			},
			//搜索
			searchOrder() {
					this.currentPage = 1;
					//调接口
					this.getList()
			},
			toResetOrder() {
					this.currentPage = 1;
					this.search.phone = '';
					this.search.level = '';
					this.due_dt='';
					this.search.start_time = '';
 		      this.search.end_time ='';
					this.getList()
			}
	}
}
</script>
<style lang="scss" scoped>
::v-deep.el-select .el-input {
	width: 130px;
}
::v-deep.input-with-select .el-input-group__prepend {
	background-color: #fff;
}
</style>