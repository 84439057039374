<template>
	<div>
		<div class="wrapper-main">
			<div class="model-content">
				 <el-tabs v-model="activeName" @tab-click="handleClick">
				    <el-tab-pane label="商城配送" name="first">
						<storedelivery ref="storedelivery"></storedelivery>
					</el-tab-pane>
				    <el-tab-pane label="地址库" name="second">
						<addresssequence ref="addresssequence"></addresssequence>
					</el-tab-pane>
					<el-tab-pane label="自提" name="third">
						<selfPickup></selfPickup>
					</el-tab-pane>
				  </el-tabs>
				
			</div>
		</div>
	</div>
</template>

<script>
	import storedelivery from '../storedelivery/index.vue'
	import addresssequence from '../addresssequence/index.vue'
	import selfPickup from '../selfPickup/index.vue'
	export default {
		components: {
			storedelivery,
			addresssequence,
			selfPickup
		},
		data() {
			return {
				activeName:'first'
			}
		},
		mounted() {
			if(localStorage.getItem('addressBack') == 1){
				this.activeName='second';
				this.$refs.addresssequence.addressListData()
			}else if(localStorage.getItem('addressBack') == 2){
				this.activeName='third';
				this.$refs.selfPickup.addressListData()
			}
			
			
			
		},
		methods:{
			handleClick(tab){
				if(tab.name=='second'){
					this.$refs.addresssequence.addressListData()
				}
				if(tab.name=='first'){
					window.localStorage.removeItem('addressBack')
					
				}
				
			}
		}

	}
</script>

<style scoped lang="scss">
	
</style>
